import React, { memo } from 'react';
import PropTypes from 'prop-types';

const RightArrowIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 13 8">
      <path d="M5.66014276,7.16797312 L0.355467902,1.86329827 C0.227863901,1.73569426 0.164062109,1.58074699 0.164062109,1.39845562 C0.164062109,1.21616425 0.227863901,1.06121698 0.355467902,0.933612982 L0.957028968,0.332051916 C1.08463297,0.204447915 1.23958024,0.136088703 1.42187161,0.12697428 C1.60416298,0.117859857 1.75911025,0.17710423 1.88671425,0.304708231 L6.1249854,4.54297938 L10.3632565,0.304708231 C10.4908605,0.17710423 10.6458078,0.117859857 10.8280992,0.12697428 C11.0103906,0.136088703 11.1653378,0.204447915 11.2929418,0.332051916 L11.8945029,0.933612982 C12.0221069,1.06121698 12.0859087,1.21616425 12.0859087,1.39845562 C12.0859087,1.58074699 12.0221069,1.73569426 11.8945029,1.86329827 L6.58982804,7.16797312 C6.46222404,7.31380596 6.30727677,7.3867226 6.1249854,7.3867226 C5.94269403,7.3867226 5.78774676,7.31380596 5.66014276,7.16797312 Z" />
    </svg>
  );
};

RightArrowIcon.propTypes = {
  className: PropTypes.string,
};

RightArrowIcon.defaultProps = { className: '' };

export default memo(RightArrowIcon);
