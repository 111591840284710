import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '../menu';

const DesktopNav = (props) => {
  const { items, className } = props;

  return (
    <ul className="nav-menu d-none d-md-block align-center">
      <Menu items={items} isMobile={false} className={className} type="desktop" />
    </ul>
  );
};

DesktopNav.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

DesktopNav.defaultProps = {
  items: [],
  className: '',
};

export default memo(DesktopNav);
