import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import './how-it-works.scss';
import { Step } from '../step';
import Icons from '../../images/icons';

const HowItWorks = ({ metadata }) => {
  const [shouldInsertEmptyFragment, setShouldInsertEmptyFragment] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') setShouldInsertEmptyFragment(window.innerWidth > 1279);
  });

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    focusOnSelect: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          arrows: true,
          className: 'on-navigation-arrows',
        },
      },
    ],
  };

  return (
    <div className={`container ${metadata.className || ''}`}>
      <h2>{metadata.title}</h2>
      <hr />
      <Slider {...settings}>
        {metadata?.items?.map((s, i) => (
          <Step
            id={s.id}
            key={`step-${s?.id}`}
            className={metadata.itemClassName}
            icon={React.createElement(Icons[s.icon], { className: s.iconClassName })}
            title={s.title}
            description={s.content}
            bulletpoints={s.bulletpoints}
            count={`${i + 1}`}
          />
        ))}
        {shouldInsertEmptyFragment && <></>}
        {shouldInsertEmptyFragment && <></>}
      </Slider>
      <p className="on-legend">{metadata.legend}</p>
    </div>
  );
};

HowItWorks.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string,
    itemClassName: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        iconClassName: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
  }),
};

HowItWorks.defaultProps = {
  metadata: {
    title: 'Title',
    itemClassName: '',
    items: [],
  },
};

export default memo(HowItWorks);
