export default {
  tradeIn: {
    hero: {
      className: '',
      backgroundImage: {
        url: '{{heroBackground}}',
        alt: 'Modern home',
        style: {},
      },
      agent: {
        name: '{{agentName}}',
        description: '{{agentDescription}}',
        avatarImageSource: '{{agentPhoto}}',
        showPhoto: true,
      },
      textContainer: {
        className: '',
        title: 'Buy now, sell later',
        subtitle: '',
        text: 'Work with the top Long Beach Real Estate agents to move into your new home now and get full market value for your old home.',
        // text: 'Work with {{agency}} to move into your new home now and get full market value for your old home.',
      },
    },
    howItWorks: {
      className: '',
      itemClassName: '',
      title: 'How HomeLight Trade-In® works',
      legend:
        '* Based on HomeLight Trade-In® transactions closed between Dec 2019 and Feb 2021, calculated using HomeLight’s valuation vs the home’s purchase price.',
      items: [
        {
          id: 'on-how-slide-0',
          title: "Get HomeLight's purchase price",
          content:
            'HomeLight will evaluate your property for the program and, if approved, let you know how much of your equity you can unlock for the purchase of your new home. If approved, you can use the equity unlock for the following:',
          bulletpoints: [
            { text: 'The downpayment on your new property' },
            { text: 'Moving expenses' },
            { text: 'Closing expenses' },
            { text: 'Property repairs' },
          ],
          icon: 'ContractIcon',
          iconClassName: 'document-icon',
        },
        {
          id: 'on-how-slide-1',
          title: 'Make a strong offer on your new home',
          content:
            'When you and {{agency}} have found the home of your dreams, you’ll be ready to make an offer with no lending or home sale contingency. This means you’re more likely to close and can do so on your own timeline.',
          icon: 'MoneyHouseIcon',
          iconClassName: 'dream-home-money-icon',
        },
        {
          id: 'on-how-slide-2',
          title: 'Move in on your schedule',
          content:
            'Once your offer is accepted, everything comes together in days, not months. HomeLight buys your home at the purchase price so you get the cash to close on your dream home and control when you move.',
          icon: 'KeysIcon',
          iconClassName: 'keys-icon',
        },
      ],
    },
    callToAction: {
      className: 'section-cta-light',
      title: 'Ready to buy before you sell?',
      buttonContent: 'Start Here',
    },
    testimonials: {
      className: '',
      title: "Don't take our word for it, take theirs.",
      superTitle: {
        show: false,
        content: 'CLIENT STORIES',
      },
      items: [
        {
          id: 'testimonial-0',
          name: 'Jason C.',
          text: "The fact that we're both teachers on teacher incomes, and we were able to use the HomeLight Trade-In program to live in the house of our dreams is pretty incredible.",
          title: 'HomeLight Trade-In Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-jason-c.png',
            className: null,
          },
        },
        {
          id: 'testimonial-1',
          name: 'Suzanne W.',
          title: 'HomeLight Trade-In Client',
          text: 'The idea of getting a home ready to sell while you live there is overwhelming. HomeLight Trade-In removes that stress and allows potential buyers complete flexibility to view your home at their convenience.',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-suzanne-w.png',
          },
        },
        {
          id: 'testimonial-2',
          name: 'Eric and Nicole T.',
          title: 'HomeLight Trade-In Clients',
          text: "If there was an easy button in real estate transactions, this would be it. We were able to buy our perfect home because we didn't have to wait to list our house and sell it to have a bona fide offer.",
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-eric-and-nicole.png',
          },
        },
        {
          id: 'testimonial-3',
          name: 'Omer T.',
          text: 'HomeLight Trade-In enabled us to break out of our current budget into a higher budget. Moving into a bigger house with a swimming pool and a huge backyard are definitely things you appreciate every day.',
          title: 'HomeLight Trade-In Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-omer-t.png',
          },
        },
        {
          id: 'testimonial-4',
          name: 'Laura G.',
          text: 'The HomeLight Trade-In process only took a few weeks! It was a real estate miracle — and exactly what I needed to facilitate my transition of moving to a new home, state, and job in less than six weeks!!',
          title: 'HomeLight Trade-In Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-laura-g.png',
          },
        },
      ],
    },
    whyHomelight: {
      className: '',
      image: {
        src: 'https://si-homelight.s3.amazonaws.com/sites/homelight/why-homelight.jpg',
        alt: 'Home interior',
        style: {},
      },
      heading: {
        title: 'Why HomeLight?',
        subTitle: 'HomeLight and {{agency}} are changing the way we transact real estate.',
      },
      textContainer: {
        className: '',
        content: [
          'HomeLight’s mission is to empower people to make smarter decisions during one of life’s most important moments: buying or selling a home.',
          'They build data-driven and innovative products that give more control to buyers and sellers while reducing risk and uncertainty.',
          'HomeLight has helped over 1 million clients.',
        ],
        link: {
          url: 'https://www.homelight.com/testimonials',
          text: 'Read real reviews from HomeLight customers',
          show: false,
        },
      },
    },
    clientFAQ: {
      className: '',
      privacyPolicy: {
        text: 'HomeLight privacy policy.',
        url: 'https://www.homelight.com/privacy-policy',
      },
      items: [
        {
          id: 'faq-howToGetStarted',
          title: 'How do I get started with HomeLight Trade-In?',
          texts: [
            {
              content: "Let us know you're interested ",
            },
            {
              content: 'here. ',
              url: '#heroForm',
            },
            {
              content: "We'll be in touch to discuss your home buying goals.",
            },
          ],
          className: '',
        },
        {
          id: 'faq-whatDoesItCost',
          title: 'What does it cost to use HomeLight Trade-In?',
          texts: [
            {
              content: 'We charge a fee to purchase, own, and sell your current home. Details can be found ',
            },
            { content: 'here.', url: 'https://www.homelight.com/trade-in/terms' },
          ],
          className: '',
        },
        {
          id: 'faq-howIsMyHomeSold',
          title: 'How is my home sold with HomeLight Trade-In?',
          texts: [
            {
              content:
                'We work with you and {{agency}} throughout the listing and sale process. Our goal is for you to get into the home of your dreams on your timeline while receiving full market value for your current home. Once we’ve purchased your home, we prepare and list it with {{agency}}. If the home sells for more than the price we paid for it, we’ll give you the additional cash minus selling costs and program fees. ',
            },
            {
              url: 'https://homelight.com/trade-in',
              content: 'Learn more here.',
            },
          ],
        },
      ],
    },
  },
  cashOffer: {
    hero: {
      className: '',
      backgroundImage: {
        url: '{{heroBackground}}',
        alt: 'Modern home',
        style: {},
      },
      agent: {
        name: '{{agentName}}',
        description: '{{agentDescription}}',
        avatarImageSource: '{{agentPhoto}}',
        showPhoto: true,
      },
      textContainer: {
        className: '',
        title: 'Buying has never been so simple',
        subtitle: '',
        text: 'Work with the top Long Beach Real Estate agents to access the savings, certainty and speed of cash — for free— so you’re more likely to win your dream home.',
        // text: 'Work with {{agency}} to access the savings, certainty, and speed of cash - for free - so you’re more likely to win your dream home.',
      },
    },
    howItWorks: {
      className: '',
      itemClassName: '',
      title: 'How HomeLight Cash Offer® works',
      legend:
        '* Based on HomeLight Cash Offer® purchases closed between January and December 2021, when compared to HomeLight clients who made traditional offers',
      items: [
        {
          id: 'on-how-slide-0',
          title: 'Say hello cash and goodbye contingencies',
          content:
            "Before you and {{agency}} search for a new home, it's important to be prepared to buy the house you fall in love with. HomeLight will do all of the work up front to qualify both you and your new home, so once you sign a contract it's a sure thing.",
          icon: 'AwardCheckIcon',
          iconClassName: 'award-check-icon',
        },
        {
          id: 'on-how-slide-1',
          title: 'Make a Cash Offer on the home, and win it',
          content:
            "Sellers value certainty. Since your offer is backed by cash, you're 3x more likely to win versus offers with a financing contingency.* That gives you a real edge in negotiations and allows you the peace of mind to plan your move.",
          icon: 'MoneyHouseIcon',
          iconClassName: 'dream-home-money-icon',
        },
        {
          id: 'on-how-slide-2',
          title: 'Set a guaranteed closing date',
          content:
            "Get your calendar and write your closing date in pen. You will close on time because that's how it should be done. HomeLight puts their own money behind their promise.",
          icon: 'ContractIcon',
          iconClassName: 'document-icon',
        },
      ],
    },
    callToAction: {
      className: '',
      title: 'Ready to buy your dream home on your terms?',
      buttonContent: 'Get Started',
    },
    testimonials: {
      className: '',
      title: 'Take their word for it',
      superTitle: {
        show: true,
        content: 'CLIENT STORIES',
      },
      items: [
        {
          id: 'testimonial-0',
          name: 'Sherry & Jonathan K.',
          title: 'HomeLight Cash Offer Clients',
          text: 'This was our first bid on a home and because we used HomeLight Cash Offer, it was accepted! We would probably still be searching for homes if we hadn’t used Homelight.',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-sherry-and-jonathan.jpg',
          },
        },
        {
          id: 'testimonial-1',
          name: 'Danny L.',
          title: 'HomeLight Cash Offer Client',
          text: 'This was way faster than any other lender out there. I would definitely recommend HomeLight Cash Offer to others. It was fast, transparent, and very efficient.',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-danny-l.jpeg',
          },
        },
        {
          id: 'testimonial-2',
          name: 'Madeleine M.',
          text: 'Our experience was great. Being able to make a cash offer is really what helped us be able to get our house. We weren’t the highest offer, but we were able to make our offer attractive enough for it to be chosen.',
          title: 'HomeLight Cash Offer Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-madeleine-m.jpg',
            className: null,
          },
        },
        {
          id: 'testimonial-3',
          name: 'Alden Y. & Chelsea S.',
          text: 'Our first offer with HomeLight Cash Offer got accepted. We were competing with 9 other offers and we weren’t the highest. It was perfect for the seller because they had a guaranteed quick close, and now we’re in our first home.',
          title: 'HomeLight Cash Offer Clients',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-alden-and-chelsea.jpg',
          },
        },
      ],
    },
    whyHomelight: {
      className: '',
      image: {
        src: 'https://si-homelight.s3.amazonaws.com/sites/homelight/why-homelight.jpg',
        alt: 'Home interior',
        style: {},
      },
      heading: {
        title: 'Why HomeLight?',
        subTitle: 'HomeLight and {{agency}} are changing the way we transact real estate.',
      },
      textContainer: {
        className: '',
        content: [
          'HomeLight’s mission is to empower people to make smarter decisions during one of life’s most important moments: buying or selling a home.',
          'They build data-driven and innovative products that give more control to buyers and sellers while reducing risk and uncertainty.',
          'HomeLight has helped over one million clients.',
        ],
        link: {
          url: 'https://www.homelight.com/testimonials',
          text: 'Read real reviews from HomeLight customers',
        },
      },
    },
    clientFAQ: {
      className: '',
      privacyPolicy: {
        text: 'HomeLight privacy policy.',
        url: 'https://www.homelight.com/privacy-policy',
      },
      items: [
        {
          id: 'faq-howToGetStarted',
          title: 'Cash Offer lets you buy your house on your terms in as few as 8 days',
          texts: [
            {
              content:
                'HomeLight helps you make the strongest offer possible, by backing it with their cash and appraisal protection. HomeLight will close on time no matter what.\n\n',
            },
            {
              content:
                "HomeLight will commit to a 21-day closing for $0.00. That's right, free. If something unexpected happens during the escrow period and all conditions have been met, HomeLight will purchase the home on your behalf and resell it to you later for the same price. ",
            },
            {
              content: 'Learn more here.',
              url: 'https://www.homelight.com/cash-offer',
            },
          ],
          className: '',
        },
        {
          id: 'faq-howIsMyHomeSold',
          title: 'Cash Offer Express',
          texts: [
            {
              content:
                'If you need to move faster, HomeLight will buy your home as fast as the law allows (in as few as 8 days) for a {{expressFee}} Express fee.**',
            },
          ],
        },
      ],
    },
  },
  substitute: {
    'tradeIn.hero.textContainer.text': 'agency',
    'cashOffer.hero.textContainer.text': 'agency',
    'tradeIn.howItWorks.items.[1].content': 'agency',
    'cashOffer.howItWorks.items.[0].content': 'agency',
    'tradeIn.howItWorks.items.[2].content': 'agency',
    'tradeIn.whyHomelight.heading.subTitle': 'agency',
    'cashOffer.whyHomelight.heading.subTitle': 'agency',
    'tradeIn.clientFAQ.items.[2].texts.[0].content': 'agency',
    'cashOffer.clientFAQ.items[1].texts.[0].content': 'expressFee',
  },
  replace: {
    'tradeIn.hero.agent.name': 'agency',
    'cashOffer.hero.agent.name': 'agency',
    'tradeIn.hero.agent.description': 'agentDescription',
    'cashOffer.hero.agent.description': 'agentDescription',
    'tradeIn.hero.agent.avatarImageSource': 'agentAvatar',
    'cashOffer.hero.agent.avatarImageSource': 'agentAvatar',
    'tradeIn.hero.backgroundImage.url': 'heroBackground',
    'cashOffer.hero.backgroundImage.url': 'heroBackground',
  },
  defaultData: {
    agency: 'The HomeLight Team',
    agentName: 'Kathryn Murphy',
    agentDescription: 'Top-agent in New York, NY',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/homelight/agent.png',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/homelight/hero-bg.jpg',
    expressFee: '1%',
  },
};
