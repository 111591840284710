const SITE_TYPE = {
  TRADE_IN: 'TRADE_IN',
  CASH_OFFER: 'CASH_OFFER',
};

const SITE_TYPE_CONFIG = [
  { type: SITE_TYPE.TRADE_IN, path: ['tradein', 'trade-in', 'trade_in', 'ti'], licence_index: 1 },
  { type: SITE_TYPE.CASH_OFFER, path: ['cashoffer', 'cash-offer', 'cash_offer', 'co'], licence_index: 2 },
];

function getLicence() {
  return process.env.GATSBY_HAITI_LICENCE ? [...process.env.GATSBY_HAITI_LICENCE] : undefined;
}

function licenceExist() {
  const licence = getLicence();
  return licence && Array.isArray(licence);
}

function checkLicence(config) {
  console.log(config);
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  const licence = getLicence();
  if (licence) {
    return licence[config.licence_index - 1] === '1';
  }
  return false;
}

function getDefaultSiteType() {
  if (process.env.NODE_ENV === 'development') {
    return SITE_TYPE.TRADE_IN;
  }

  if (!licenceExist()) {
    return SITE_TYPE.TRADE_IN;
  }

  const licence = getLicence();
  if (licence[0] === '1') {
    return SITE_TYPE.TRADE_IN;
  }
  return SITE_TYPE.CASH_OFFER;
}

function getSiteType(location) {
  const defaultType = getDefaultSiteType();
  const path = location?.replace(/\//g, '').toLowerCase();

  if (!path) {
    return defaultType;
  }

  for (let i = 0; i < SITE_TYPE_CONFIG.length; i += 1) {
    const config = SITE_TYPE_CONFIG[i];

    if (config.path.includes(path) && checkLicence(config)) {
      return config.type;
    }
  }

  return defaultType;
}

module.exports = { SITE_TYPE, getSiteType, SITE_TYPE_CONFIG };
