import React, { memo, useEffect, useRef, useState } from 'react';
import './contact-form.scss';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { formStatus } from '../../../enums/form-status';
import ContactFormSuccess from './contact-form-success';
import LoaderIcon from '../../../images/icons/loader-icon';

const ContactForm = ({ location: { search }, sentStatus, setSentStatus, siteType, submitContactForm, title }) => {
  const query = queryString.parse(search);

  const [terms, setTerms] = useState(false);
  const [formData, setFormData] = useState({});
  const [formSent, setFormSent] = useState(false);
  const [isFormDataEmpty, setIsFormDataEmpty] = useState(true);

  useEffect(() => {
    setIsFormDataEmpty(!formData?.email || !formData?.name || !formData?.phone);
  }, [formData]);

  const form = useRef(null);
  const clearForm = () => {
    setFormData({});
    setIsFormDataEmpty(true);
  };

  const validateFormData = () => form.current?.reportValidity();
  const updateFormData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const getFormData = () => {
    const {
      utm_medium: utmMedium = '',
      utm_source: utmSource = '',
      utm_content: utmContent = '',
      utm_term: utmTerm = '',
      utm_campaign: utmCampaign = '',
    } = query || {};

    return {
      Firstname: formData.name,
      Phone: formData.phone,
      Email: formData.email,
      Site: title,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_content: utmContent,
      utm_term: utmTerm,
      utm_campaign: utmCampaign,
      type: siteType,
    };
  };
  const submitForm = () => {
    const data = getFormData();
    const validated = validateFormData();

    // setFormSent(true);
    if (!validated) return;
    setSentStatus(formStatus.SUBMITTING);

    setTimeout(async () => {
      try {
        const result = await submitContactForm(data);

        if (result.filter((res) => res.status !== 200).length > 0) {
          setSentStatus(formStatus.ERROR);
          return;
        }

        setSentStatus(formStatus.SUCCESS);
        clearForm();
        setFormSent(true);
      } catch (err) {
        setSentStatus(formStatus.ERROR);
        console.log(err);
      }
    }, 1000);
  };

  const isDisabled = !terms || formSent || isFormDataEmpty;

  return (
    <>
      <form id="formID" ref={form}>
        <input
          required="required"
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateFormData}
          disabled={formSent}
        />
        <input
          required="required"
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateFormData}
          disabled={formSent}
        />
        <input
          required="required"
          type="tel"
          name="phone"
          placeholder="Phone number"
          onChange={updateFormData}
          disabled={formSent}
        />
        <>
          <div id="submit" className="button" disabled={isDisabled} onClick={isDisabled ? () => {} : submitForm}>
            Submit
          </div>
        </>
        <br />
        <div className="checkboxDivAlign">{sentStatus === formStatus.SUBMITTING && <LoaderIcon />}</div>
        <br />
        <div className="checkboxDivAlign">
          <input
            id="terms-input"
            required="required"
            type="checkbox"
            className="checkbox"
            onChange={() => setTerms(!terms)}
            value={terms}
          />
          <label for="terms-input" className="checkbox-label">
            I have read and accept <a href="https://www.homelight.com/terms">Terms and Conditions</a>.
          </label>
        </div>
      </form>
      {formSent && <ContactFormSuccess closeForm={() => setFormSent(false)} />}
    </>
  );
};

ContactForm.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.any,
  }),
  setSentStatus: PropTypes.func,
  submitContactForm: PropTypes.func,
  siteType: PropTypes.string,
  title: PropTypes.string,
};

ContactForm.defaultProps = {
  location: { search: '', pathname: '' },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSentStatus: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submitContactForm: () => {},
  siteType: '',
};

export default memo(ContactForm);
