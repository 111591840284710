import React, { memo, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { ContactForm, ContactFormSubmitting, ContactFormSuccess, ContactFormError } from '../../../components';

import { formStatus } from '../../../enums/form-status';
import './contact-modal-container.scss';

const ContactModalContainer = (props) => {
  const { isOpen, openModalHandler, submitContactForm, siteType } = props;

  const [sentStatus, setSentStatus] = useState(formStatus.NEW);

  const closeForm = () => {
    setSentStatus(formStatus.NEW);
    openModalHandler(false);
  };

  return (
    isOpen && (
      <main className="cash-close-page">
        <Modal className="modal-dialog on-ac-rm-modal" isOpen={isOpen} ariaHideApp={false}>
          <div className="modal" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close agent-intro-complete-btn close-btn"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => openModalHandler(false)}>
                  <span area-hidden="true">×</span>
                </button>
              </div>
              {sentStatus === formStatus.NEW && (
                <ContactForm setSentStatus={setSentStatus} submitContactForm={submitContactForm} siteType={siteType} />
              )}
              {sentStatus === formStatus.SUBMITTING && <ContactFormSubmitting />}
              {sentStatus === formStatus.SUCCESS && <ContactFormSuccess closeForm={closeForm} />}
              {sentStatus === formStatus.ERROR && <ContactFormError closeForm={closeForm} />}
            </div>
          </div>
        </Modal>
      </main>
    )
  );
};

ContactModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  openModalHandler: PropTypes.func,
  submitContactForm: PropTypes.func,
  siteType: PropTypes.string,
};

ContactModalContainer.defaultProps = {
  isOpen: false,
  openModalHandler: () => {},
  submitContactForm: () => {},
  siteType: '',
};

export default memo(ContactModalContainer);
