import React, { useState, memo } from 'react';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Heading = ({ item }) => {
  const className = item.className ? item.className : cs('on-footer-heading');
  const Comp = item.elementType ? item.elementType : 'div';

  return item.url ? (
    <a id={item.id} href={item.url} className={className} style={item.style}>
      {item.data}
    </a>
  ) : (
    <Comp id={item.id} className={className} style={item.style}>
      {item?.data}
    </Comp>
  );
};

export const ItemContent = ({ item }) => {
  const className = item.className ? item.className : cs('on-footer-itemcontent');

  return (
    <p id={item.id} className={className} style={item.style}>
      {item?.items?.map((el, index) => {
        if (el.url)
          return (
            <a id={el.id} href={el.url} key={`${el.url[0]}${index}`} rel="noreferrer" className={el.className}>
              {el.imageUrl ? (
                <img
                  id={`item-content-img-${el.id}`}
                  src={el.imageUrl}
                  alt={el.alt}
                  className={el.imageClassName}
                  style={el.style}
                />
              ) : (
                el.content
              )}
            </a>
          );
        else if (!el.url && el.imageUrl)
          return <img id={el.id} src={el.imageUrl} alt={el.alt} className={el.imageClassName} style={el.style} />;
        return el.content;
      })}
    </p>
  );
};

export const Image = ({ item, logoImg }) => {
  const className = item.className ? item.className : cs('on-footer-image');
  return item?.linkUrl ? (
    <a id={item.id} className={className} key={item.id} href={item.linkUrl}>
      <img id={`image-${item.id}`} src={item.url ? item.url : logoImg} alt={item.alt} style={item.style} />
    </a>
  ) : (
    <img id={item.id} className={className} src={item.url ? item.url : logoImg} alt={item.alt} style={item.style} />
  );
};

export const FooterMenu = ({ item }) => {
  const itemClassName = item.itemClassName ? item.itemClassName : cs('on-footer-menu-item');
  const menuClassName = item.className ? item.className : cs('on-footer-menu');
  const submenusClassName = item.submenusClassName ? item.submenusClassName : cs('on-footer-submenu');

  return (
    <div id={item.id} className={menuClassName} style={item.style}>
      {item?.items?.map((el) =>
        el.url ? (
          <a
            id={el.id}
            key={el.id}
            href={el.url}
            className={cs(el.className ? el.className : itemClassName, 'link')}
            style={el.style}
          >
            {el.data}
          </a>
        ) : el.submenuItems ? (
          <MemoSubmenu el={el} itemClassName={itemClassName} submenusClassName={submenusClassName} />
        ) : (
          <p id={el.id} key={el.id} className={el.className ? el.className : itemClassName} style={el.style}>
            {' '}
            {el.data}
          </p>
        ),
      )}
    </div>
  );
};

export const SocialIcons = ({ item }) => {
  const itemClassName = item.itemClassName ? item.itemClassName : cs('on-footer-socials-item');
  const className = item.className ? item.className : cs('on-footer-socials');
  return (
    <div id={item.id} className={className}>
      {item?.items?.map((el) =>
        el.iconUrl ? (
          <a
            id={el.id}
            key={el.id}
            href={el.url}
            className={el.className ? el.className : itemClassName}
            style={el.style}
          >
            <img
              id={`social-img-${el.id}`}
              alt={el.alt ? el.alt : 'icon'}
              src={el.iconUrl}
              className={`${el.className ? el.className : itemClassName} icon`}
            />{' '}
            {el.data}
          </a>
        ) : (
          <a
            id={el.id}
            key={el.id}
            href={el.url}
            className={el.className ? el.className : itemClassName}
            style={el.style}
          >
            <FontAwesomeIcon
              icon={[el.iconTypeClassName, el.iconClassName]}
              className={`${el.className ? el.className : itemClassName} icon`}
            />{' '}
            {el.data}
          </a>
        ),
      )}
    </div>
  );
};

export const FooterList = ({ item }) => {
  return (
    <div id={item.id} className={item.className}>
      {item?.items?.map((el) => (
        <div id={el.id} className={item.rowClassName} key={el.id} style={el.style}>
          {el?.data?.map((elt) =>
            elt.url ? (
              <a id={elt.id} href={elt.url} key={elt.id} style={elt.style}>
                {elt.text}
              </a>
            ) : (
              <span id={elt.id} key={elt.id} style={elt.style}>
                {elt.text}
              </span>
            ),
          )}
        </div>
      ))}
    </div>
  );
};

const Submenu = ({ el, itemClassName, submenusClassName }) => {
  const [expandedFolder, setExpandedFolder] = useState('');
  const expandFolderClick = (e) => {
    const id = e.target.id;

    if (id === expandedFolder) {
      setExpandedFolder('');
      return;
    }

    setExpandedFolder(id);
  };

  const label = (
    <label
      id={el.id}
      className={cs('on-footer-folder-label', itemClassName, el.submenuLabelClassName, {
        'submenu-label-active': el.id === expandedFolder,
      })}
      onClick={(e) => expandFolderClick(e)}
    >
      {el.data}
    </label>
  );

  return (
    <div id={`container-${el.id}`} key={el.id} className={'on-footer-folder'} style={el.style}>
      {label}
      <div
        id={`submenu-${el.id}`}
        className={cs(
          el.id === expandedFolder ? 'on-footer-submenu-expanded' : 'on-footer-submenu',
          submenusClassName,
          el.submenuClassName,
        )}
      >
        {el?.submenuItems?.map((subEl) => (
          <a
            id={subEl.id}
            key={subEl.id}
            href={subEl.url}
            className={cs(subEl.submenuItemClassName, el.submenuItemsClassName)}
            style={subEl.style}
          >
            {subEl.data}
          </a>
        ))}
      </div>
    </div>
  );
};

export const MemoSubmenu = React.memo(Submenu);
