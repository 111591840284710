import React, { memo } from 'react';
import PropTypes from 'prop-types';

const LoaderIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "transparent",
        display: "block",
      }}
      width="64px"
      height="64px"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="25"
        cy="25"
        fill="none"
        stroke="#b6d9f1"
        stroke-width="1.5"
        r="5"
        stroke-dasharray="23.5619449019 8.85398163397"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 25 25;360 25 25"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

LoaderIcon.propTypes = {
  className: PropTypes.string,
};

LoaderIcon.defaultProps = { className: '' };

export default memo(LoaderIcon);
