import { default as ContractIcon } from './contract-icon';
import { default as MoneyHouseIcon } from './money-house-icon';
import { default as KeysIcon } from './keys-icon';
import { default as MoneyIcon } from './money-icon';
import { default as DreamHouseIcon } from './dream-house-icon';
import { default as AwardCheckIcon } from './award-check-icon';
import { default as LeftArrowIcon } from './left-arrow-icon';
import { default as RightArrowIcon } from './right-arrow-icon';
import { default as LoaderIcon } from './loader-icon';

export default {
  ContractIcon,
  MoneyHouseIcon,
  KeysIcon,
  MoneyIcon,
  DreamHouseIcon,
  AwardCheckIcon,
  LeftArrowIcon,
  RightArrowIcon,
  LoaderIcon,
};
