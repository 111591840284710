import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MoneyIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 100.07 96">
      <g id="Layer_2" data-name="Layer 2">
        <g id="value">
          <circle fill="#e8eef9" cx="52.07" cy="48" r="48" />
          <polygon fill="#fff" points="4.07 35.23 0 71.84 36.3 81.22 62.58 47.24 33.48 26.79 4.07 35.23" />
          <path fill="#e8eef9" d="M29.52,48C25,50.06,14.78,49.87,14.78,49.87V39.92l23.6-1.83S34,45.84,29.52,48Z" />
          <rect
            fill="#008ce7"
            x="13.23"
            y="30.57"
            width="76.09"
            height="36.14"
            transform="translate(-19.08 55.43) rotate(-49)"
          />
          <rect
            fill="#008ce7"
            x="16.03"
            y="29.93"
            width="76.09"
            height="36.14"
            transform="translate(-17.91 54.81) rotate(-47)"
          />
          <rect
            x="18.84"
            y="29.4"
            width="76.09"
            height="36.14"
            transform="translate(-16.9 54.13) rotate(-45)"
            fill="#46b6ff"
          />
          <path
            fill="#008ce7"
            d="M43.07,82.45,21.9,61.29,70.71,12.48,91.88,33.65ZM24.73,61.29,43.07,79.63l46-46L70.71,15.31Z"
          />
          <circle fill="#008ce7" cx="56.89" cy="47.47" r="9.47" />
          <path fill="#008ce7" d="M23.32,61.29l5.37-5.38a7.6,7.6,0,0,1,0,10.75Z" />
          <path fill="#008ce7" d="M43.07,81l-5.38-5.37a7.6,7.6,0,0,1,10.75,0Z" />
          <path fill="#008ce7" d="M70.71,13.89l5.38,5.38a7.6,7.6,0,0,1-10.75,0Z" />
          <path fill="#008ce7" d="M90.46,33.65,85.09,39a7.6,7.6,0,0,1,0-10.75Z" />
          <path
            fill="#fff"
            d="M39.58,81.31c1,.91,8.07,4.52,9.57,4.65s3.82-.42,4.58-2.25a4.12,4.12,0,0,0-1.06-4.93c-1.21-1-8.37-3-10-3.19a4.32,4.32,0,0,0-3.94,1.71A3.49,3.49,0,0,0,39.58,81.31Z"
          />
          <path
            fill="#fff"
            d="M48.81,74.4c1.11,1,7.57,4.47,9.18,4.61s4.09-.45,4.9-2.42a4.38,4.38,0,0,0-1.13-5.27c-1.3-1-7.9-2.8-9.63-3a4.66,4.66,0,0,0-4.22,1.83A3.75,3.75,0,0,0,48.81,74.4Z"
          />
          <path
            fill="#fff"
            d="M68.43,58.07c1.1,1,5.41,3.53,7,3.67s4.09-.45,4.9-2.41a4.42,4.42,0,0,0-1.13-5.28c-1.31-1-5.74-1.86-7.47-2.11a4.68,4.68,0,0,0-4.23,1.83A3.74,3.74,0,0,0,68.43,58.07Z"
          />
          <path
            fill="#fff"
            d="M59.21,66.44c1.1,1,5.4,3.53,7,3.67s4.08-.45,4.9-2.41A4.41,4.41,0,0,0,70,62.43c-1.31-1-5.74-1.86-7.47-2.12a4.66,4.66,0,0,0-4.23,1.84A3.72,3.72,0,0,0,59.21,66.44Z"
          />
          <path
            fill="#fff"
            d="M21.35,48.65c4.51-3.09,9.71-9.81,12.25-10.13,2.81-.36,13.66,4,16.19,4.6a19.74,19.74,0,0,0,7,.37c1.5-.28,2.78-1.34,2.86-2.06.17-1.54-21.85-14.08-26.21-14.64S4.07,35.24,4.07,35.24L8.68,55S16.73,51.83,21.35,48.65Z"
          />
        </g>
      </g>
    </svg>
  );
};

MoneyIcon.propTypes = {
  className: PropTypes.string,
};

MoneyIcon.defaultProps = { className: '' };

export default memo(MoneyIcon);
