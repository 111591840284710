export const socials = [
  {
    id: 0,
    linkClassName: 'btn-facebook',
    url: 'https://www.facebook.com/',
    iconClassName: 'facebook',
    text: 'Facebook',
  },
  {
    id: 1,
    linkClassName: 'btn-instagram',
    url: 'https://www.instagram.com/',
    iconClassName: 'instagram',
    text: 'Instagram',
  },
  {
    id: 2,
    linkClassName: 'btn-twitter',
    url: 'https://www.twitter.com/',
    iconClassName: 'twitter',
    text: 'Twitter',
  },
  {
    id: 3,
    linkClassName: 'btn-linkedin',
    url: 'https://www.linkedin.com/',
    iconClassName: 'linkedin',
    text: 'LinkedIn',
  },
  {
    id: 4,
    linkClassName: 'btn-youtube',
    url: 'https://www.youtube.com/',
    iconClassName: 'youtube',
    text: 'Youtube',
  },
];
