import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Image, FooterMenu, FooterList, SocialIcons, ItemContent } from './footer-components';

import './footer.scss';

const components = {
  heading: Heading,
  image: Image,
  menu: FooterMenu,
  socialIcons: SocialIcons,
  list: FooterList,
  itemContent: ItemContent,
};

const Footer = ({ metadata, logoImg }) => {
  const footerContent = () => (
    <div className={`on-footer-section ${metadata.sectionClassName}`}>
      <div className={metadata.className} key={metadata.id}>
        {metadata?.columns?.map((col) => (
          <div key={col.items.index} className={col.className}>
            {col?.items?.map((item) => {
              const Comp = components[item.type];
              return <Comp item={item} key={item.id} logoImg={logoImg} />;
            })}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <footer id="footer" className="on-footer">
      <div className="footer-container">{footerContent()}</div>
    </footer>
  );
};

Footer.propTypes = {
  metadata: PropTypes.any,
  children: PropTypes.node,
  logoImg: PropTypes.any,
};

Footer.defaultProps = { children: null, metadata: null, logoImg: null };

export default memo(Footer);
