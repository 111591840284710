export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'remax logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/iz/logo.svg',
        url: 'https://new-dimension-100423240.remax.com/',
      },
      breakpoint: 999999,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-iz-header-image',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-test',
          content: 'Open',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-iz-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-iz-header-item',
        menuClassName: 'on-iz-header-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-iz-header-menu-item',
        subMenuClassName: 'on-iz-header-submenu',
        subMenuItemClassName: 'on-iz-header-submenu-item',
        headerItems: [],
        items: [
          {
            id: 'remax-menu-item',
            content: 'RE/MAX New Dimension',
            url: '',
          },
          {
            id: 'buy-menu-item',
            content: 'Buy',
            className: 'on-iz-header-menu-heading on-iz-header-item',
          },
          {
            id: 'buying-guide-menu-item',
            content: 'Buying Guide',
          },
          {
            id: 'loan-depot-menu-item',
            content: 'Loan Depot',
          },
          {
            id: 'sell-menu-item',
            content: 'Sell',
            className: 'on-iz-header-menu-heading on-iz-header-item',
          },
          {
            id: 'home-value-menu-item',
            content: 'Home Value Estimates',
            url: 'https://new-dimension-100423240.remax.com/home-value-estimates',
          },
          {
            id: 'selling-guide-menu-item',
            content: 'Selling Guide',
            url: 'https://new-dimension-100423240.remax.com/sell/sellers-guide',
          },
          {
            id: 'trade-in-menu-item',
            content: 'Homelight Trade In',
            url: 'https://trade-in.adamegroup.com/',
          },
          {
            id: 'resources-menu-item',
            content: 'Resources',
            className: 'on-iz-header-menu-heading on-iz-header-item',
          },
          {
            id: 'account-benefits-menu-item',
            content: 'Account Benefits',
            url: 'https://new-dimension-100423240.remax.com/account-benefits',
          },
          {
            id: 'agent-roster-menu-item',
            content: 'Agent Roster',
            url: 'https://new-dimension-100423240.remax.com/real-estate-agents',
          },
          {
            id: 'remax-info-menu-item',
            content: 'RE/MAX Info',
            className: 'on-iz-header-menu-heading on-iz-header-item',
          },
          {
            id: 'our-office-menu-item',
            content: 'Our Office',
            url: 'https://new-dimension-100423240.remax.com/our-office',
          },
          {
            id: 'charitable-menu-item',
            content: 'Charitable Causes',
            url: 'https://new-dimension-100423240.remax.com/remax-info/charitable-causes',
          },
          {
            id: 'about-menu-item',
            content: 'About RE/MAX',
          },
          {
            id: 'blog-menu-item',
            content: 'RE/MAX Blog',
            url: 'https://news.remax.com/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'iz-main-footer',
      className: 'on-iz-footer iz-main-footer',
      sectionClassName: 'on-iz-main-footer-container',
      columns: [
        {
          id: 'iz-main-footer-column-one',
          className: '',
          items: [
            {
              id: 'iz-footer-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'RE/MAX New Dimension',
            },
            {
              id: 'iz-footer-phone',
              type: 'itemContent',
              className: 'on-iz-footer-phone',
              items: [
                {
                  content: 'Main: ',
                },
                {
                  content: '(714) 902-6052',
                  url: 'tel:714-902-6052',
                },
              ],
            },
            {
              id: 'iz-footer-address',
              type: 'heading',
              elementType: 'p',
              data: '1820 East 1st Street, Suite #240, Santa Ana, CA 92705',
            },
            {
              id: 'iz-footer-socials',
              type: 'socialIcons',
              className: 'iz-footer-socials',
              items: [
                {
                  id: 'iz-footer-facebook',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/remax.newdimension?ref=bookmarks',
                },
                {
                  id: 'iz-footer-twitter',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                  url: 'https://twitter.com/remaxnewdimen',
                },
              ],
            },
            {
              id: 'iz-hline',
              className: 'on-iz-footer-hline',
              type: 'heading',
              elementType: 'hr',
            },
          ],
        },
        {
          id: 'iz-main-footer-column-two',
          className: 'on-iz-main-footer-column-two',
          items: [
            {
              id: 'iz-footer-contact-us',
              className: 'iz-footer-contact-us',
              type: 'heading',
              data: 'Contact Us',
            },
          ],
        },
      ],
    },
    {
      id: 'iz-privacy-footer',
      className: 'on-iz-footer iz-privacy-footer',
      sectionClassName: 'on-iz-privacy-footer-container',
      columns: [
        {
          id: 'iz-privacy-footer-column-one',
          className: 'on-iz-privacy-footer-column-one',
          items: [
            {
              id: 'iz-privacy-footer-heading-one',
              type: 'heading',
              elementType: 'p',
              data: 'This page contains advertisements.',
            },
            {
              id: 'iz-privacy-footer-heading-two',
              type: 'heading',
              elementType: 'p',
              data: 'Licensed in CA. ',
            },
            {
              id: 'iz-privacy-footer-heading-three',
              type: 'heading',
              elementType: 'p',
              data: 'Each office independently owned and operated.',
            },
            {
              id: 'iz-privacy-footer-heading-four',
              type: 'heading',
              elementType: 'p',
              data: 'If you have a disability that is preventing you from experiencing this website, call (714) 902-6052.',
            },
            {
              id: 'iz-privacy-footer-',
              type: 'itemContent',
              className: '',
              items: [
                {
                  content: 'Powered by ',
                },
                {
                  content: 'booj.',
                  url: 'https://booj.com/',
                },
              ],
            },
          ],
        },
        {
          id: 'iz-privacy-footer-column-two',
          className: 'on-iz-privacy-footer-column-two',
          items: [
            {
              id: 'iz-realtor-logo',
              type: 'image',
              className: 'on-iz-realtor-logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/iz/logo-footer-equal-realtor.png',
            },
            {
              id: 'iz-footer-privacy-menu',
              type: 'menu',
              className: 'on-iz-footer-privacy-menu',
              items: [
                {
                  id: 'footer-privacy',
                  data: 'PRIVACY NOTICE',
                  url: 'https://new-dimension-100423240.remax.com/privacy-notice',
                },
                {
                  id: 'footer-terms',
                  data: 'TERMS OF USE',
                  url: 'https://new-dimension-100423240.remax.com/terms-of-use',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Edgar Adame',
    agentDescription: 'Top agents in Southern California',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/iz/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/iz/hero-bg.jpg',
  },
};
