import cs from 'classnames';
import { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import './client-faq.scss';
import { ChevronIcon } from '../icons';
import { Bulletpoints } from '../bulletpoints';

const ClientFAQ = ({ metadata }) => {
  const [openFAQ, setOpenFAQ] = useState('');
  const toggleFAQ = (value) => setOpenFAQ(openFAQ === value ? '' : value);

  const disclosure = useMemo(() => {
    const disc =
      metadata?.disclosure?.links?.reduce((item, index) => {
        item.push(index, { item: '|' });
        return item;
      }, []) ?? [];
    disc.pop();
    return disc;
  }, [metadata]);

  return (
    <div className={cs('on-client-faq')}>
      <div className="faq-component">
        <h2>FAQs</h2>
        <div className="faq">
          {metadata?.items?.map((item) => (
            <div className={cs('accordion', { [item.className]: item.className })} key={item.id} id={item.id}>
              <div className="accordion-top" onClick={() => toggleFAQ(item.id)}>
                {item.title}
                <div className="expand-icon">
                  <ChevronIcon
                    className={cs('chevron-icon-component', {
                      right: openFAQ !== item.id,
                    })}
                  />
                </div>
              </div>
              <div
                className={cs('accordion-details', {
                  details: openFAQ === item.id,
                  'details-hidden': openFAQ !== item.id,
                })}
              >
                <p>
                  {item?.texts?.map((text, index) => {
                    if (text.url)
                      return (
                        <a href={text.url} key={`${text.url[0]}${index}`} rel="noreferrer">
                          {text.content}
                        </a>
                      );
                    return text.content;
                  })}
                </p>
                {item?.bulletpoints?.length > 0 && <Bulletpoints bulletpoints={item?.bulletpoints} />}
              </div>
            </div>
          ))}
        </div>
        <div className="on-faq-privacy-container">
          <a href={metadata.privacyPolicy.url} className="on-faq-privacy-policy">
            {metadata.privacyPolicy.text}
          </a>
        </div>
        <div className="on-disclosure">
          <p>{metadata?.disclosure?.text}</p>
          <div className="on-disclosure-links">
            {disclosure?.map((link) => {
              if (link?.url?.length > 0) return <a href={link.url}>{link.item}</a>;
              else return <p>{link.item}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

ClientFAQ.propTypes = {
  metadata: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        className: PropTypes.string,
        title: PropTypes.string,
        texts: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string,
            content: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
};

ClientFAQ.defaultProps = {
  metadata: {
    items: [],
  },
};

export default memo(ClientFAQ);
