import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import MenuItem from './menu-item';

const Menu = (props) => {
  const { items, isMobile } = props;
  const [activeMenuItemId, setActiveMenuItemId] = useState(1); // TODO: parse from URL

  return items // move to props?
    ?.filter((el) => (!isMobile && el.displayOnDesktop) || (isMobile && el.displayOnMobile))
    ?.map((item) => (
      <MenuItem
        item={item}
        itemClass=""
        key={item.id}
        isActive={item.id === activeMenuItemId}
        isMobile={isMobile}
        onItemClick={setActiveMenuItemId}
      />
    ));
};

Menu.propTypes = {
  items: PropTypes.array,
  isMobile: PropTypes.bool,
};

Menu.defaultProps = {
  items: [],
  isMobile: false,
};

export default memo(Menu);
