import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SocialLink from './social-link';

const SocialLinks = (props) => {
  const { className, items } = props;

  return (
    <span className={className}>
      {items?.map((item) => (
        <SocialLink key={item.id} item={item} {...props} />
      ))}
    </span>
  );
};

SocialLinks.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  showText: PropTypes.bool,
  items: PropTypes.array,
  target: PropTypes.string,
};

SocialLinks.defaultProps = {
  className: 'social-nav',
  itemClassName: 'btn',
  showText: false,
  items: [],
  target: '_blank',
};

export default memo(SocialLinks);
