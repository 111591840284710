import { memo } from 'react';
import PropTypes from 'prop-types';

import './hero-container.scss';
import { Hero } from '../../../components';

const HeroContainer = ({ metadata, siteType, title, openModalHandler, submitContactForm }) => (
    <Hero metadata={metadata} siteType={siteType} title={title} openModalHandler={openModalHandler} submitContactForm={submitContactForm}></Hero>
  );
HeroContainer.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    agent: PropTypes.shape({
      avatarImageSource: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
    textContainer: PropTypes.shape({
      className: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
  siteType: PropTypes.string,
  title: PropTypes.string,
  openModalHandler: PropTypes.func,
  submitContactForm: PropTypes.func,
};

export default memo(HeroContainer);
