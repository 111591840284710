import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import './testimonials.scss';

const Testimonials = ({ metadata }) => {
  const [currentTestiomnial, setCurrentTestiomnial] = useState(0);

  const nextTestimonial = () => {
    if (currentTestiomnial === metadata.items.length - 1) return;

    setCurrentTestiomnial(currentTestiomnial + 1);
  };

  const prevTestiomnial = () => {
    if (currentTestiomnial === 0) return;

    setCurrentTestiomnial(currentTestiomnial - 1);
  };

  return (
    <div
      className={cs('on-testimonials-container', {
        [metadata.className]: metadata.className,
      })}
    >
      <div className="on-testimonials-content">
        <div>
          {metadata.superTitle.show && <h1>{metadata.superTitle.content}</h1>}
          <h2 className="on-testimonials-title">{metadata.title}</h2>
        </div>
        <div className="on-testimonials-quote">
          <img
            src={metadata.items[currentTestiomnial].image.url}
            data-src={metadata.items[currentTestiomnial].image.url}
            className={cs('lazyloaded', {
              [metadata.items[currentTestiomnial].image.className]: metadata.items[currentTestiomnial].image.className,
            })}
            alt="author"
          />
          <div className="pre-load">
            {metadata.items.length > 1 &&
              metadata?.items?.map((item) => <img key={item.id} src={item.image.url} alt="preload" />)}
          </div>
          <blockquote>
            <q>{metadata.items[currentTestiomnial].text}</q>
            <cite>
              <strong>{metadata.items[currentTestiomnial].name}</strong>
              <br />
              <span>{metadata.items[currentTestiomnial].title}</span>
            </cite>
          </blockquote>
        </div>
        {metadata.items.length > 1 && (
          <nav className="on-testimonials-navigator">
            <div className="on-testimonials-nav-arrows">
              <div
                className={cs('on-testimonials-prev', { disabled: currentTestiomnial === 0 })}
                onClick={prevTestiomnial}
              />
            </div>
            {metadata?.items?.map((t, index) => (
              <div
                key={t.id}
                className={cs('on-testimonials-testimonial', { current: currentTestiomnial === index })}
              />
            ))}
            <div className="on-testimonials-nav-arrows">
              <div
                className={cs('on-testimonials-next', {
                  disabled: currentTestiomnial + 1 === metadata.items.length,
                })}
                onClick={nextTestimonial}
              />
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    superTitle: PropTypes.shape({ show: PropTypes.bool, content: PropTypes.string }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          className: PropTypes.string,
        }),
      }),
    ),
  }),
};

Testimonials.defaultProps = {
  metadata: {
    className: '',
    title: 'Title',
    superTitle: {
      show: false,
      content: '',
    },
    items: [],
  },
};

export default memo(Testimonials);
