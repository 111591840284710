import cs from 'classnames';
import { memo } from 'react';
import PropTypes from 'prop-types';

import '../client-faq/client-faq.scss';

const TermsAndConditions = ({ metadata }) => (
  <div className={cs('on-client-faq')}>
    <div className="faq-component">
      <h2 className="on-tac-title">TERMS & CONDITIONS</h2>
      <div className="faq">
        {metadata?.items?.map((item) => (
          <div className={cs('accordion', { [item.className]: item.className })} key={item.id} id={item.id}>
            <div className="accordion-top">{item.title}</div>
            <div className={cs('accordion-details', 'details')}>
              <p>
                {item?.texts?.map((text, index) => {
                  if (text.url)
                    return (
                      <a href={text.url} key={`${text.url[0]}${index}`} rel="noreferrer">
                        {text.content}
                      </a>
                    );

                  return text.content;
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <p className="on-tac-legend">
          ** The fee is dependent on your market, the lender you use, and the number of days HomeLight owns your home.
          Details can be found <a href="/">here.</a>
        </p>
      </div>
      <div className="on-faq-privacy-container">
        <a href={metadata.privacyPolicy.url} className="on-faq-privacy-policy">
          {metadata.privacyPolicy.text}
        </a>
      </div>
    </div>
  </div>
);

TermsAndConditions.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    privacyPolicy: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        className: PropTypes.string,
        title: PropTypes.string,
        texts: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string,
            content: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
};

TermsAndConditions.defaultProps = {
  metadata: {
    items: [],
  },
};

export default memo(TermsAndConditions);
