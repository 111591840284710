import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Bulletpoints } from '../bulletpoints';

import './step.scss';

const Step = (props) => {
  const { icon, title, description, bulletpoints, count } = props;
  return (
    <div className="slick-slide">
      <div className="numeration">{count}</div>
      <div className="on-step">
        <div className="icon">{icon}</div>
        <div className="desc">
          <h3>{title}</h3>
          <p>{description}</p>
          {bulletpoints?.length > 0 && <Bulletpoints bulletpoints={bulletpoints} />}
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  count: PropTypes.string,
};

Step.defaultProps = {
  icon: null,
  title: '',
  description: '',
  count: '1',
};

export default memo(Step);
