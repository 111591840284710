import { memo } from 'react';
import PropTypes from 'prop-types';

import './client-faq-container.scss';
import { ClientFAQ } from '../../../components';

const ClientFaqContainer = ({ metadata }) => <ClientFAQ metadata={metadata} />;

ClientFaqContainer.propTypes = {
  metadata: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        className: PropTypes.string,
        title: PropTypes.string,
        texts: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string,
            content: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
};

export default memo(ClientFaqContainer);
