import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './homelight-table.scss';

const HomelightTable = (props) => {
  const { children } = props;
  return (
    <table className="table-contents">
      <tbody>{children}</tbody>
    </table>
  );
};

HomelightTable.propTypes = {
  children: PropTypes.node,
};

HomelightTable.defaultProps = { children: null };

export default memo(HomelightTable);
