import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ContractIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 96 102.29">
      <g id="Layer_2" data-name="Layer 2">
        <g id="guaranteed">
          <circle cx="48" cy="48" r="48" fill="#e8eef9" />
          <polygon fill="#fff" points="77.8 101.88 18.2 101.88 18.2 19.16 65.13 19.16 77.8 31.86 77.8 101.88" />
          <polygon fill="#bbd3ea" points="77.8 31.86 65.13 31.86 65.13 19.16 77.8 31.86" />
          <rect fill="#bbd3ea" x="22.79" y="44.43" width="45.84" height="2.3" />
          <rect fill="#bbd3ea" x="22.79" y="51.33" width="45.84" height="2.3" />
          <rect fill="#bbd3ea" x="22.79" y="58.22" width="22.92" height="2.3" />
          <rect fill="#bbd3ea" x="22.79" y="76.61" width="22.92" height="2.3" />
          <path
            fill="#273653"
            d="M23.56,92.35a.81.81,0,0,1-.34-.08c-.83-.37-.53-1.33-.17-2.44a6.31,6.31,0,0,0,.49-3,.71.71,0,1,1,1.36-.39,6.05,6.05,0,0,1-.34,3.31c.46-.52,1-1.12,1.41-1.63,3.16-3.73,4.45-5.08,5.51-4.49s.54,2.44-.2,4.76c-.21.65-.49,1.52-.62,2.12a28.09,28.09,0,0,0,2-2.15c1.69-1.91,2.53-2.87,3.36-2.33s.53,1.76.24,3.2c0,.22-.1.5-.14.75l.12-.08a6.6,6.6,0,0,1,1.93-.86,6.19,6.19,0,0,1,3.53.24.7.7,0,0,1,.41.91.71.71,0,0,1-.92.41,4.79,4.79,0,0,0-2.72-.17,5.39,5.39,0,0,0-1.5.7c-.66.39-1.48.87-2.08.22-.42-.45-.27-1.18,0-2.4.06-.3.15-.74.21-1.12-.42.42-.94,1-1.34,1.47-2,2.26-3.09,3.41-4,2.87s-.49-2,.21-4.21a18.54,18.54,0,0,0,.76-2.83,35.21,35.21,0,0,0-3.63,3.91C25,91.46,24.22,92.35,23.56,92.35Z"
          />
          <path
            fill="#273653"
            d="M28.9,35.15a1.76,1.76,0,0,1-.64,1.43,3.19,3.19,0,0,1-1.8.65v1.15h-.77V37.25a6.24,6.24,0,0,1-2.39-.48V35.29a5.43,5.43,0,0,0,1.17.43,6.2,6.2,0,0,0,1.22.22V34.2l-.37-.14a4.12,4.12,0,0,1-1.57-1,1.81,1.81,0,0,1-.47-1.27,1.66,1.66,0,0,1,.64-1.33,3.24,3.24,0,0,1,1.77-.64V29h.77v.83a6.52,6.52,0,0,1,2.31.51l-.52,1.31a6.24,6.24,0,0,0-1.79-.43v1.65a8.54,8.54,0,0,1,1.55.73,2.17,2.17,0,0,1,.68.67A1.74,1.74,0,0,1,28.9,35.15ZM25,31.84a.55.55,0,0,0,.17.41,2.45,2.45,0,0,0,.55.33V31.26C25.21,31.33,25,31.53,25,31.84Zm2.25,3.4a.53.53,0,0,0-.19-.4,2.38,2.38,0,0,0-.57-.33V35.9C27,35.81,27.22,35.59,27.22,35.24Z"
          />
          <path
            fill="#273653"
            d="M35.7,36h-1v1.69H33V36H29.53V34.81l3.59-5.28h1.59v5.14h1ZM33,34.67V33.29c0-.24,0-.57,0-1s0-.7,0-.77h0a5.7,5.7,0,0,1-.5.9l-1.5,2.26Z"
          />
          <path
            fill="#273653"
            d="M42,33.62a5.75,5.75,0,0,1-.7,3.17,2.44,2.44,0,0,1-2.17,1A2.4,2.4,0,0,1,37,36.76a5.61,5.61,0,0,1-.72-3.14,5.71,5.71,0,0,1,.7-3.19,2.71,2.71,0,0,1,4.29.05A5.54,5.54,0,0,1,42,33.62Zm-4,0a6.22,6.22,0,0,0,.26,2.16.9.9,0,0,0,.87.65.91.91,0,0,0,.88-.66,6.48,6.48,0,0,0,.26-2.15A6.2,6.2,0,0,0,40,31.46a.9.9,0,0,0-1.74,0A6.25,6.25,0,0,0,38,33.62Z"
          />
          <path
            fill="#273653"
            d="M48.55,33.62a5.66,5.66,0,0,1-.7,3.17,2.44,2.44,0,0,1-2.17,1,2.4,2.4,0,0,1-2.13-1.06,5.61,5.61,0,0,1-.72-3.14,5.71,5.71,0,0,1,.7-3.19,2.71,2.71,0,0,1,4.29.05A5.54,5.54,0,0,1,48.55,33.62Zm-4,0a6.47,6.47,0,0,0,.26,2.16.9.9,0,0,0,.87.65.91.91,0,0,0,.88-.66,6.17,6.17,0,0,0,.27-2.15,5.92,5.92,0,0,0-.28-2.16.9.9,0,0,0-1.74,0A6.51,6.51,0,0,0,44.55,33.62Z"
          />
          <path
            fill="#273653"
            d="M51.49,34.31l.75-1L54,31.46h1.92l-2.48,2.71,2.63,3.54h-2l-1.8-2.53-.73.58v2H49.85V29h1.71v3.88l-.09,1.42Z"
          />
          <path
            d="M84.28,84.42c-.45-1.39,0-3.23-.85-4.41s-2.75-1.32-3.92-2.16-1.89-2.61-3.27-3.06-3,.56-4.45.56-3.11-1-4.45-.56S65.22,77,64.07,77.85,61,78.85,60.15,80s-.4,3-.86,4.4S57.4,87,57.4,88.48s1.47,2.72,1.89,4.06,0,3.23.85,4.41,2.76,1.32,3.93,2.16,1.88,2.61,3.27,3.07,3-.56,4.45-.56,3.1,1,4.45.56,2.11-2.22,3.27-3.07,3.07-1,3.92-2.16.4-3,.85-4.41,1.9-2.57,1.9-4.06S84.71,85.76,84.28,84.42Z"
            fill="#46b6ff"
          />
          <path
            fill="#fff"
            d="M69.31,94l-5-5a.77.77,0,0,1,0-1.09l1.09-1.1a.77.77,0,0,1,1.09,0l3.38,3.38L77.1,83a.76.76,0,0,1,1.09,0l1.09,1.09a.77.77,0,0,1,0,1.09L70.4,94a.77.77,0,0,1-1.09,0Z"
          />
        </g>
      </g>
    </svg>
  );
};

ContractIcon.propTypes = {
  className: PropTypes.string,
};

ContractIcon.defaultProps = { className: '' };

export default memo(ContractIcon);
