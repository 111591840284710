import React, { memo } from 'react';

import './header-container.scss';
import { Header } from '@on-haiti/shared-ui/components';
import customComponents from '../custom-components';

const HeaderContainer = ({ metadata }) => {
  return metadata?.map((header) => <Header key={header.id} metadata={header} customComponents={customComponents} />);
};

export default memo(HeaderContainer);
