export default {
  tradeIn: {
    hero: {
      className: '',
      backgroundImage: {
        url: '{{heroBackground}}',
        alt: 'Modern home',
        style: {},
      },
      agent: {
        name: '{{agentName}}',
        description: '{{agentDescription}}',
        avatarImageSource: '{{agentPhoto}}',
        showPhoto: true,
      },
      textContainer: {
        className: '',
        title: 'Buy now, sell later',
        subtitle: '',
        text: 'Work with {{agency}} to move into your new home with speed and certainty — while getting the strongest possible offer for your old home.',
      },
    },
    howItWorks: {
      className: '',
      itemClassName: '',
      title: 'How HomeLight Buy Before You Sell Works',
      items: [
        {
          id: 'on-how-slide-0',
          title: 'Unlock equity from your home with ease',
          content:
            'HomeLight will evaluate your property for the program and, if approved, let you know how much of your equity you can unlock for the purchase of your new home. If approved, you can use the equity unlock for the following:',
          bulletpoints: [
            { text: 'The downpayment on your new property' },
            { text: 'Moving expenses' },
            { text: 'Closing expenses' },
            { text: 'Property repairs' },
          ],
          icon: 'ContractIcon',
          iconClassName: 'document-icon',
        },
        {
          id: 'on-how-slide-1',
          title: 'Buy your dream home with confidence — and without a double move',
          content:
            'You and {{agency}} will be able to make a strong offer on your new home without a home sale contingency — and you can avoid moving twice.',
          icon: 'MoneyHouseIcon',
          iconClassName: 'dream-home-money-icon',
        },
        {
          id: 'on-how-slide-2',
          title: 'Maximize the sales price of your current home',
          content: '{{agency}} will list your vacant home on the market to attract the strongest offer possible.',
          icon: 'KeysIcon',
          iconClassName: 'keys-icon',
        },
      ],
    },
    callToAction: {
      className: 'section-cta-light',
      title: 'Ready to buy before you sell?',
      buttonContent: 'Start Here',
    },
    testimonials: {
      className: '',
      title: "Don't take our word for it, take theirs.",
      superTitle: {
        show: false,
        content: 'CLIENT STORIES',
      },
      items: [
        {
          id: 'testimonial-0',
          name: 'Jason C.',
          text: "The fact that we're both teachers on teacher incomes, and we were able to use the HomeLight Trade-In program to live in the house of our dreams is pretty incredible.",
          title: 'HomeLight Trade-In Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-jason-c.png',
            className: null,
          },
        },
        {
          id: 'testimonial-1',
          name: 'Suzanne W.',
          title: 'HomeLight Trade-In Client',
          text: 'The idea of getting a home ready to sell while you live there is overwhelming. HomeLight Trade-In removes that stress and allows potential buyers complete flexibility to view your home at their convenience.',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-suzanne-w.png',
          },
        },
        {
          id: 'testimonial-2',
          name: 'Eric and Nicole T.',
          title: 'HomeLight Trade-In Clients',
          text: "If there was an easy button in real estate transactions, this would be it. We were able to buy our perfect home because we didn't have to wait to list our house and sell it to have a bona fide offer.",
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-eric-and-nicole.png',
          },
        },
        {
          id: 'testimonial-3',
          name: 'Omer T.',
          text: 'HomeLight Trade-In enabled us to break out of our current budget into a higher budget. Moving into a bigger house with a swimming pool and a huge backyard are definitely things you appreciate every day.',
          title: 'HomeLight Trade-In Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-omer-t.png',
          },
        },
        {
          id: 'testimonial-4',
          name: 'Laura G.',
          text: 'The HomeLight Trade-In process only took a few weeks! It was a real estate miracle — and exactly what I needed to facilitate my transition of moving to a new home, state, and job in less than six weeks!!',
          title: 'HomeLight Trade-In Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-laura-g.png',
          },
        },
      ],
    },
    whyHomelight: {
      className: '',
      image: {
        src: 'https://si-homelight.s3.amazonaws.com/sites/homelight/why-homelight.jpg',
        alt: 'Home interior',
        style: {},
      },
      heading: {
        title: 'Why HomeLight?',
        subTitle: 'HomeLight and {{agency}} are changing the way we transact real estate.',
      },
      textContainer: {
        className: '',
        content: [
          'HomeLight’s mission is to empower people to make smarter decisions during one of life’s most important moments: buying or selling a home.',
          'They build data-driven and innovative products that give more control to buyers and sellers while reducing risk and uncertainty.',
          'HomeLight has helped over 1 million clients.',
        ],
        link: {
          url: 'https://www.homelight.com/testimonials',
          text: 'Read real reviews from HomeLight customers',
          show: false,
        },
      },
    },
    clientFAQ: {
      className: '',
      privacyPolicy: {
        text: 'HomeLight privacy policy.',
        url: 'https://www.homelight.com/privacy-policy',
      },
      disclosure: {
        text: '*Fees subject to change; minimums apply.',
        links: [
          {
            item: 'HomeLight Home Loans NMLS # 1529229',
          },
          {
            item: 'Equal Housing Lender',
          },
          {
            item: 'NMLSconsumeraccess.org',
            url: 'https://nmlsconsumeraccess.org/',
          },
          {
            item: 'https://www.homelighthomeloans.com/licenses-and-lending-practices-disclosure',
            url: 'https://www.homelighthomeloans.com/licenses-and-lending-practices-disclosure',
          },
        ],
      },
      items: [
        {
          id: 'faq-howToGetStarted',
          title: 'How do I get started with HomeLight Buy Before You Sell?',
          texts: [
            {
              content: "Let us know you're interested ",
            },
            {
              content: 'here. ',
              url: '#heroForm',
            },
            {
              content: "We'll be in touch to discuss your home buying goals.",
            },
          ],
          className: '',
        },
        {
          id: 'faq-whatDoesItCost',
          title: 'What does it cost to use HomeLight Buy Before You Sell?',
          texts: [
            {
              content:
                'It’s free to get started and see how much equity you can unlock. If you decide to transact using the program, the fee is: ',
            },
          ],
          bulletpoints: [
            { text: '2.4%* of the home sale if you choose to use an outside lender and closing company.' },
            { text: '1.7%* of the home sale if you use our lending and closing services.' },
          ],
          className: '',
        },
        {
          id: 'faq-howIsMyHomeSold',
          title: 'How do you determine how much equity I can unlock from my current home?',
          texts: [
            {
              content:
                'The amount of equity you can unlock is determined using HomeLight’s proprietary algorithm, which takes into consideration the estimation of your home’s value, current market conditions, projected market risk, borrower financials, outstanding loans, and more. You’ll be able to unlock a portion of the overall equity you have in your home upfront, prior to selling the home — and will receive the remainder of your equity after the home sells, less program fees and expenses.',
            },
          ],
        },
        {
          id: 'faq-whatIfMyHomeDoesntSell',
          title: 'What if my current home doesn’t sell?',
          texts: [
            {
              content:
                'HomeLight partners with top agents nationwide to make buying and selling a home simpler, more certain, and satisfying for all. We work closely with {{agency}} to sell your home. If your home does not sell within 90 days, HomeLight will buy it and work with {{agency}} to list and sell it. You’ll receive any profit from the sale, after deducting HomeLight’s incurred costs.',
            },
          ],
        },
      ],
    },
  },
  cashOffer: {
    hero: {
      className: '',
      backgroundImage: {
        url: '{{heroBackground}}',
        alt: 'Modern home',
        style: {},
      },
      agent: {
        name: '{{agentName}}',
        description: '{{agentDescription}}',
        avatarImageSource: '{{agentPhoto}}',
        showPhoto: true,
      },
      textContainer: {
        className: '',
        title: 'Buying has never been so simple',
        subtitle: '',
        text: 'Work with {{agency}} to access the savings, certainty, and speed of cash - for free - so you’re more likely to win your dream home.',
      },
    },
    howItWorks: {
      className: '',
      itemClassName: '',
      title: 'How HomeLight Cash Offer® works',
      legend:
        '*Based on Anderson, Dana. “All-Cash Homebuyers Are Four Times More Likely To Win A Bidding War” Redfin, 14 March 2022.',
      items: [
        {
          id: 'on-how-slide-0',
          title: 'Get fully cash approved',
          content:
            'Before you and {{agency}} search for a new home, it’s important to be prepared to buy the house you fall in love with. HomeLight will do all the work upfront to evaluate you for the program and, if approved, underwrite you in full to empower you to make a HomeLight Cash Offer.',
          icon: 'AwardCheckIcon',
          iconClassName: 'award-check-icon',
        },
        {
          id: 'on-how-slide-1',
          title: 'Make a Cash Offer on your new home, and win it',
          content:
            'Sellers value certainty. Since your offer is backed by HomeLight’s proof-of-funds rather than a pre-approval letter, you’re 4x more likely to win versus offers with financial or appraisal contingencies.*',
          icon: 'MoneyHouseIcon',
          iconClassName: 'dream-home-money-icon',
        },
        {
          id: 'on-how-slide-2',
          title: 'Close in as few as 12 days',
          content: 'Get ready to pack! You’re able to move into your new home immediately upon closing.',
          icon: 'ContractIcon',
          iconClassName: 'document-icon',
        },
        {
          id: 'on-how-slide-3',
          title: 'Mortgage closing',
          content: 'HomeLight refinances you into a long-term mortgage ~20-30 days later.',
          icon: 'ContractIcon',
          iconClassName: 'dream-home-money-icon',
        },
      ],
    },
    callToAction: {
      className: '',
      title: 'Ready to buy your dream home on your terms?',
      buttonContent: 'Get Started',
    },
    testimonials: {
      className: '',
      title: 'Take their word for it',
      superTitle: {
        show: true,
        content: 'CLIENT STORIES',
      },
      items: [
        {
          id: 'testimonial-0',
          name: 'Sherry & Jonathan K.',
          title: 'HomeLight Cash Offer Clients',
          text: 'This was our first bid on a home and because we used HomeLight Cash Offer, it was accepted! We would probably still be searching for homes if we hadn’t used Homelight.',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-sherry-and-jonathan.jpg',
          },
        },
        {
          id: 'testimonial-1',
          name: 'Danny L.',
          title: 'HomeLight Cash Offer Client',
          text: 'This was way faster than any other lender out there. I would definitely recommend HomeLight Cash Offer to others. It was fast, transparent, and very efficient.',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-danny-l.jpeg',
          },
        },
        {
          id: 'testimonial-2',
          name: 'Madeleine M.',
          text: 'Our experience was great. Being able to make a cash offer is really what helped us be able to get our house. We weren’t the highest offer, but we were able to make our offer attractive enough for it to be chosen.',
          title: 'HomeLight Cash Offer Client',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-madeleine-m.jpg',
            className: null,
          },
        },
        {
          id: 'testimonial-3',
          name: 'Alden Y. & Chelsea S.',
          text: 'Our first offer with HomeLight Cash Offer got accepted. We were competing with 9 other offers and we weren’t the highest. It was perfect for the seller because they had a guaranteed quick close, and now we’re in our first home.',
          title: 'HomeLight Cash Offer Clients',
          image: {
            url: 'https://si-homelight.s3.amazonaws.com/sites/homelight/testimonials/testimonial-alden-and-chelsea.jpg',
          },
        },
      ],
    },
    whyHomelight: {
      className: '',
      image: {
        src: 'https://si-homelight.s3.amazonaws.com/sites/homelight/why-homelight.jpg',
        alt: 'Home interior',
        style: {},
      },
      heading: {
        title: 'Why HomeLight?',
        subTitle: 'HomeLight and {{agency}} are changing the way we transact real estate.',
      },
      textContainer: {
        className: '',
        content: [
          'HomeLight’s mission is to empower people to make smarter decisions during one of life’s most important moments: buying or selling a home.',
          'They build data-driven and innovative products that give more control to buyers and sellers while reducing risk and uncertainty.',
          'HomeLight has helped over one million clients.',
        ],
        link: {
          url: 'https://www.homelight.com/testimonials',
          text: 'Read real reviews from HomeLight customers',
        },
      },
    },
    clientFAQ: {
      className: '',
      privacyPolicy: {
        text: 'HomeLight privacy policy.',
        url: 'https://www.homelight.com/privacy-policy',
      },
      disclosure: {
        text: '',
        links: [
          {
            item: 'HomeLight Home Loans NMLS # 1529229',
          },
          {
            item: 'Equal Housing Lender',
          },
          {
            item: 'NMLSconsumeraccess.org',
            url: 'https://nmlsconsumeraccess.org/',
          },
          {
            item: 'https://www.homelighthomeloans.com/licenses-and-lending-practices-disclosure',
            url: 'https://www.homelighthomeloans.com/licenses-and-lending-practices-disclosure',
          },
        ],
      },
      items: [
        {
          id: 'faq-howToGetStarted',
          title: 'How much does the program cost?',
          texts: [
            {
              content:
                'The program has no hidden costs or added fees. HomeLight Cash Offer costs the same as a traditional mortgage, so long as you use HomeLight Home Loans as your lender, and our mortgage rates are typically in-line with what you would see from other local lenders.',
            },
          ],
          className: '',
        },
        {
          id: 'faq-howMuchDoesItTake',
          title: 'How long does the HomeLight Cash Offer process take?',
          texts: [
            {
              content:
                'It depends on how quickly you are able to provide the requested documentation for your upfront underwrite. HomeLight can complete the underwrite in a few days after we have the loan application and all supporting documents. ',
            },
          ],
        },
        {
          id: 'faq-howIsMyHomeSold',
          title: 'Does HomeLight still require an appraisal?',
          texts: [
            {
              content:
                'Yes. In most cases, an appraisal is required. The appraisal occurs after your short-term cash loan closes, but before the refinance of the short-term cash loan into a long-term mortgage. This is to protect you from appraisal gap risk while removing the appraisal contingency on your offer.',
            },
          ],
        },
        {
          id: 'faq-shortTermCashLoan',
          title: 'How does the short-term cash loan work?',
          texts: [
            {
              content:
                'HomeLight provides you with a short-term Cash Loan at the same interest rate as your mortgage, which you combine with your own down payment to purchase your home. You will receive loan disclosures for both your short-term cash loan and your mortgage once you’re under contract with the seller.',
            },
          ],
        },
        {
          id: 'faq-goodFit',
          title: 'How do I know if I’m a good fit for the program?',
          texts: [
            {
              content:
                'HomeLight Cash Offer is a great fit for a variety of buyers — we’re able to approve up to $2 million in loan value.',
            },
          ],
        },
      ],
    },
  },
  substitute: {
    'tradeIn.hero.textContainer.text': 'agency',
    'cashOffer.hero.textContainer.text': 'agency',
    'tradeIn.howItWorks.items.[1].content': 'agency',
    'cashOffer.howItWorks.items.[0].content': 'agency',
    'tradeIn.howItWorks.items.[2].content': 'agency',
    'tradeIn.whyHomelight.heading.subTitle': 'agency',
    'cashOffer.whyHomelight.heading.subTitle': 'agency',
    'tradeIn.clientFAQ.items.[3].texts.[0].content': 'agency',
    'cashOffer.clientFAQ.items[1].texts.[0].content': 'expressFee',
  },
  replace: {
    'tradeIn.hero.agent.name': 'agency',
    'cashOffer.hero.agent.name': 'agency',
    'tradeIn.hero.agent.description': 'agentDescription',
    'cashOffer.hero.agent.description': 'agentDescription',
    'tradeIn.hero.agent.avatarImageSource': 'agentAvatar',
    'cashOffer.hero.agent.avatarImageSource': 'agentAvatar',
    'tradeIn.hero.backgroundImage.url': 'heroBackground',
    'cashOffer.hero.backgroundImage.url': 'heroBackground',
  },
  defaultData: {
    agency: 'The HomeLight Team',
    agentName: 'Kathryn Murphy',
    agentDescription: 'Top-agent in New York, NY',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/homelight/agent.png',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/homelight/hero-bg.jpg',
    expressFee: '1%',
  },
};
