import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ContactFormError = (props) => {
  const { closeForm } = props;
  return (
    <div className="modal-body align-center">
      <h1>Error sending message</h1>
      <p>Sorry, your message may not have been sent correctly. Please try again or contact us directly by email.</p>
      <button
        type="button"
        className="button thankyou-btn"
        id="contact-form-submit-btn"
        data-submit="true"
        onClick={closeForm}>
        Close
      </button>
    </div>
  );
};

ContactFormError.propTypes = {
  closeForm: PropTypes.func,
};

ContactFormError.defaultProps = { closeForm: () => {} };

export default memo(ContactFormError);
