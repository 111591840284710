import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import './slider-arrows.scss';
import icons from '../../images/icons';

const windowGlobal = typeof window !== 'undefined' && window;

const getShownSlideCount = () => (windowGlobal.innerWidth >= 1200 ? 4 : Math.floor(windowGlobal.innerWidth / 250));

export const PrevArrow = (props) => {
  const { onClick, currentSlide } = props;
  return (
    <div className="slick-slider slick-initialized" dir="ltr" onClick={onClick}>
      <div
        className={cs('slick-arrow', 'd-flex', 'slick-prev', currentSlide === 0 ? 'slick-disabled' : '')}
        onClick={onClick}
      >
        <div className="icon-wrap">{icons.LeftArrowIcon({ className: 'chevron-icon-component left' })}</div>
      </div>
    </div>
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
};

PrevArrow.defaultProps = {
  onClick: null,
  currentSlide: 0,
};

export const NextArrow = (props) => {
  const { onClick, currentSlide, slideCount } = props;
  return (
    <div
      className={cs(
        'slick-arrow',
        'd-flex',
        'slick-next',
        currentSlide === slideCount - getShownSlideCount() ? 'slick-disabled' : '',
      )}
      onClick={onClick}
    >
      <div className="icon-wrap">{icons.RightArrowIcon({ className: 'chevron-icon-component right' })}</div>
    </div>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
};

NextArrow.defaultProps = {
  onClick: null,
  currentSlide: 0,
  slideCount: 0,
};
