import { memo, useEffect, useState } from 'react';
import HeaderItems from './header-items';
import * as mobileMenu from './mobile-menus';
import './header.scss';

const Header = ({ metadata, customComponents }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedFolder, setExpandedFolder] = useState('');
  const [expandedSubFolder, setExpandedSubFolder] = useState('');

  useEffect(() => setIsDesktop(window?.innerWidth > metadata.breakpoint));

  const desktop = metadata.desktopMenu;
  const mobile = metadata.mobileMenu;

  const toggleMobileMenuClick = () => {
    if (isMobileMenuOpen) {
      setExpandedFolder('');
      setExpandedSubFolder('');
    }
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const expandFolderClick = (e, isSubFolder) => {
    if (isDesktop && !desktop.disableOnHoverMenuExpand) return;
    const id = e.target.id;

    if (id === expandedFolder) {
      setExpandedFolder('');
      setExpandedSubFolder('');
      return;
    }

    if (id === expandedSubFolder) {
      setExpandedSubFolder('');
      return;
    }

    if (isSubFolder) {
      setExpandedSubFolder(id);
      return;
    }

    setExpandedFolder(id);
  };

  const mapItems = (items) =>
    items.map((item) =>
      headerItemsBuilder.item(item, {
        expandedFolder,
        expandedSubFolder,
      }),
    );

  const mapItemsToSection = (items) =>
    headerItemsBuilder.headerSection(
      items.map((item) =>
        headerItemsBuilder.item(item, {
          expandedFolder,
          expandedSubFolder,
        }),
      ),
    );

  const getLogo = () =>
    headerItemsBuilder.headerSection(
      headerItemsBuilder.logo(metadata.logo, isDesktop ? desktop.logo.show : mobile.logo.show),
    );

  const getDesktopLayout = () => {
    switch (desktop.type) {
      case 'split':
        const halfItemsLength = Math.ceil(desktop.items.length / 2);
        const leftItems = [];
        const rightItems = [];

        for (let i = 0; i < desktop.items.length; i++) {
          if (i < halfItemsLength) leftItems.push(desktop.items[i]);
          else rightItems.push(desktop.items[i]);
        }

        return [mapItemsToSection(leftItems), getLogo(), mapItemsToSection(rightItems)];
      case 'one-offs':
        if (desktop.items.length === 0) return null;

        const lastItemIndex = desktop.items.length - 1;
        const firstItem = desktop.items[0];
        const lastItem = desktop.items[lastItemIndex];
        const reducedItems = desktop.items.filter((item, index) => index !== 0 && index !== lastItemIndex);

        if (!desktop.logo.show)
          return [mapItemsToSection([firstItem]), mapItemsToSection(reducedItems), mapItemsToSection([lastItem])];

        if (desktop.logo.reverseOrder)
          return [mapItemsToSection([firstItem]), mapItemsToSection([...reducedItems, lastItem]), getLogo()];

        return [getLogo(), mapItemsToSection([firstItem, ...reducedItems]), mapItemsToSection([lastItem])];
      case 'single':
      case 'center':
        if (!desktop.logo.show) return [mapItemsToSection(desktop.items)];

        if (desktop.logo.reverseOrder) return [mapItemsToSection(desktop.items), getLogo()];
        return [getLogo(), mapItemsToSection(desktop.items)];
      default:
        if (desktop.logo.reverseOrder) return [mapItemsToSection(desktop.items), getLogo()];
        return [getLogo(), mapItemsToSection(desktop.items)];
    }
  };

  const getMobileOpener = () => {
    switch (mobile.menuOpener.type) {
      case 'middle-button':
        return null;
      default:
        return mobileMenu.hamburgerOpener({
          isReverse: mobile.menuOpener.reverse,
          className: mobile.menuOpener.className,
          toggleMobileMenu: toggleMobileMenuClick,
          isMobileMenuOpen,
        });
    }
  };

  const getMobileMenu = () => {
    if (mobile.menuOpener.hide) return null;

    switch (mobile.type) {
      default:
        return headerItemsBuilder.div(
          [
            getMobileOpener(),
            mobileMenu.sideMenu(mapItems(mobile.items), {
              isReverse: mobile.menuOpener.reverse,
              isMobileMenuOpen,
              className: mobile.mobileMenuClassName,
            }),
          ],
          { className: 'on-header-mobile-menu-container' },
        );
    }
  };

  const getMobileLayout = () => {
    switch (mobile.type) {
      case 'mobile-reversed':
        if (!mobile.logo.show) return [getMobileMenu(), mapItemsToSection(mobile.headerItems)];
        return [getMobileMenu(), mapItemsToSection(mobile.headerItems), getLogo()];
      case 'mobile-reversed-center':
        if (!mobile.logo.show) return [getMobileMenu(), mapItemsToSection(mobile.headerItems)];
        return [getMobileMenu(), getLogo(), mapItemsToSection(mobile.headerItems)];
      default:
        if (!mobile.logo.show) return [mapItemsToSection(mobile.headerItems), getMobileMenu()];
        return [getLogo(), mapItemsToSection(mobile.headerItems), getMobileMenu()];
    }
  };

  const headerItemsBuilder = new HeaderItems(isDesktop, desktop, mobile, expandFolderClick, customComponents);
  return headerItemsBuilder.wrapWithHeader(isDesktop ? getDesktopLayout() : getMobileLayout(), metadata.id);
};

export default memo(Header);
