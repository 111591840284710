import React, { memo } from 'react';
import { HomelightTable } from '../../../components';
import './pricing-table-container.scss';

// TODO: move to data folder
const pricingInfo = [
  {
    id: 1,
    description: 'Market Valuation',
    value: '1,300,000',
  },
  {
    id: 2,
    description: 'Homelight Purchase Price',
    value: '1,170,000',
  },
  {
    id: 3,
    description: 'Homelight Trade-in (1.5%) Fee',
    value: '19,500',
  },
  {
    id: 4,
    description: 'Estimated Commissions, Closing, and Repair Costs',
    value: '(85544)',
  },
  {
    id: 5,
    description: 'Loan Payoff',
    value: '(650000)',
  },
  {
    id: 6,
    description: 'Equity for new home purchase',
    value: '402,656',
  },
  {
    id: 7,
    description: null,
    value: null,
  },
  {
    id: 8,
    description: 'Actual Sales Price',
    value: '1,400,000',
  },
  {
    id: 9,
    description: 'Actual Homelight Trade-In Fee',
    value: '(21000)',
  },
  {
    id: 10,
    description: 'Actual Commissions, Closing, and Repair Costs',
    value: '(95,544)',
  },
  {
    id: 11,
    description: 'Actual proceeds after market sale',
    value: '140,000',
  },
  {
    id: 12,
    description: 'Total equity received from sale',
    value: '624,356',
  },
];

const PricingTableContainer = () => (
  <div className="on-pricing-table">
    <div className="on-table">
      <div className="table-container align-center">
        <h2>What It Costs</h2>
        <p className="align-left mt-3">
          Pricing could not be simpler. In addition to paying typical Real Estate commissions, closing costs, and repair
          expenses, there is a fee that typically runs about 1.5% of the home you are selling.
        </p>
        <p className="align-left mt-1 mb-2">
          We find that most clients will recoup some or all of this fee by being able to make a stronger non-contingent
          offer on a new home AND getting top dollar for the existing home being vacant and all cleaned up. Below is a
          cost breakdown from an actual client.
        </p>
        <HomelightTable title="What It Costs">
          {pricingInfo?.map((row) => (
            <tr key={row.id}>
              <td>{row.description}</td>
              <td>
                {row.value && '$ '}
                {row.value}
              </td>
            </tr>
          ))}
        </HomelightTable>
      </div>
    </div>
  </div>
);

export default memo(PricingTableContainer);
