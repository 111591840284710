import React, { memo } from 'react';
import PropTypes from 'prop-types';

const KeysIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 96 97.76">
      <g id="Layer_2" data-name="Layer 2">
        <g id="move-in">
          <circle cx="48" cy="48" r="48" fill="#e8eef9" />
          <rect fill="#bbd3ea" x="58.06" y="1.36" width="30" height="59.72" />
          <polygon points="58.06 61.08 79.06 73.08 79.06 13.36 58.06 1.36 58.06 61.08" fill="#fff" />
          <rect fill="#bbd3ea" x="73.16" y="38.6" width="2.62" height="7.45" />
          <path
            fill="#9bb0c9"
            d="M19.68,44.14a1.49,1.49,0,0,1-1.41-1L17,39.58a11.83,11.83,0,0,1,22.24-8.1l1.3,3.58a1.5,1.5,0,0,1-2.82,1l-1.3-3.58a8.83,8.83,0,0,0-16.6,6l1.3,3.58a1.5,1.5,0,0,1-.9,1.92A1.52,1.52,0,0,1,19.68,44.14Z"
          />
          <path
            fill="#0c6eba"
            d="M50.37,67.19l5.37.47-.45,5.4,5.38.47L60.2,78.9l2.45,2.92L70.2,82l1.14-7.47L54.94,55a16,16,0,1,0-8.7,7.29Z"
          />
          <path
            d="M24.47,46.53a3.26,3.26,0,1,1-4.17-1.94A3.26,3.26,0,0,1,24.47,46.53Zm1.61,30.53L31,79.34,28.7,84.26l4.89,2.28-2.28,4.89L32.61,95l7,2.75,3.62-6.63-8.72-24A16,16,0,1,0,23.88,71Z"
            fill="#008ce7"
          />
          <polygon
            fill="#0c6eba"
            points="24.47 46.53 21.88 39.24 19.97 39.63 21.6 44.39 22.37 44.54 23.16 44.91 23.84 45.48 24.47 46.53"
          />
          <path
            fill="#9bb0c9"
            d="M20.29,46.09a1.49,1.49,0,0,1-1.41-1L17,39.56a1.5,1.5,0,0,1,2.83-1l1.92,5.52A1.5,1.5,0,0,1,20.79,46,1.47,1.47,0,0,1,20.29,46.09Z"
          />
        </g>
      </g>
    </svg>
  );
};

KeysIcon.propTypes = {
  className: PropTypes.string,
};

KeysIcon.defaultProps = { className: '' };

export default memo(KeysIcon);
