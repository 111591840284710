import { memo } from 'react';
import PropTypes from 'prop-types';

import './testimonials-container.scss';
import { Testimonials } from '../../../components';

const TestimonialsContainer = ({ metadata }) => <Testimonials metadata={metadata} />;

TestimonialsContainer.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    superTitle: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          className: PropTypes.string,
        }),
      }),
    ),
  }),
};

export default memo(TestimonialsContainer);
