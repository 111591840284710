import { memo } from 'react';
import cs from 'classnames';

import './call-to-action-container.scss';
import { CallToAction } from '../../../components';

const CallToActionContainer = (props) => (
  <div
    className={cs('on-call-to-action', {
      [props.metadata.className]: props.metadata.className,
    })}
  >
    <CallToAction {...props} title={props.metadata.title} text={props.metadata.buttonContent} />
  </div>
);

export default memo(CallToActionContainer);
