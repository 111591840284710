import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DreamHouseIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 109.56 96">
      <g id="Layer_2" data-name="Layer 2">
        <g id="house">
          <circle fill="#e8eef9" cx="48" cy="48" r="48" />
          <polygon
            fill="#e8eef9"
            points="103.56 80.82 25 80.82 25 43.81 37.27 21.79 75.56 21.79 103.56 43.81 103.56 80.82"
          />
          <polygon fill="#fff" points="61.38 80.82 13 80.82 13 54.68 45.2 31.09 61.38 43.81 61.38 80.82" />
          <polygon fill="#273653" points="109.56 43.81 65.27 43.81 34.87 19.98 79.16 19.98 109.56 43.81" />
          <rect fill="#0c6eba" x="65.27" y="43.84" width="44.29" height="2.8" />
          <polygon fill="#0c6eba" points="37.57 25.22 13 42.71 13 46.82 3.12 46.82 35.23 23.36 37.57 25.22" />
          <rect fill="#bbd3ea" x="69.06" y="56.82" width="11" height="17" />
          <rect fill="#bbd3ea" x="85.8" y="56.82" width="11" height="17" />
          <rect fill="#e8eef9" x="39.73" y="56.82" width="11" height="17" />
          <rect fill="#273653" x="23.01" y="56.82" width="11" height="24" />
          <rect fill="#fff" x="31.33" y="68.27" width="1.21" height="3.1" />
        </g>
      </g>
    </svg>
  );
};

DreamHouseIcon.propTypes = {
  className: PropTypes.string,
};

DreamHouseIcon.defaultProps = { className: '' };

export default memo(DreamHouseIcon);
