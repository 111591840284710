import { memo, useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

import { formStatus } from '../../enums/form-status';
import './hero.scss';
import { ContactForm } from '../forms';

const Hero = ({ metadata, openModalHandler, submitContactForm, title, siteType }) => {
    const [sentStatus, setSentStatus] = useState(formStatus.NEW);

  return (
    <div
      className={cs('on-hero', {
        [metadata.className]: metadata.className,
      })}
    >
      <div className="section" id="hero">
        <picture>
          <img
            alt={metadata.backgroundImage.alt}
            src={metadata.backgroundImage.url}
            style={metadata.backgroundImage.style}
          />
        </picture>
        <div
          className={cs('content', {
            [metadata.textContainer.className]: metadata.textContainer.className,
          })}
          id="heroForm"
        >
          <div className="intro-box">
            {metadata.agent.showPhoto && (
              <div className="on-agent-avatar">
                <img alt="agent avatar" src={metadata.agent.avatarImageSource} />
              </div>
            )}
            <p className="on-agent-name">{metadata.agent.name}</p>
            <p className="on-agent-description">{metadata.agent.description}</p>
            <h1>{metadata.textContainer.title}</h1>
            <p className="tradeMark">{metadata.textContainer.subtitle}</p>
            <p className="text">{metadata.textContainer.text}</p>
            <hr />
            <ContactForm sentStatus={sentStatus} setSentStatus={setSentStatus} submitContactForm={submitContactForm} siteType={siteType} title={title}/>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    agent: PropTypes.shape({
      avatarImageSource: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
    textContainer: PropTypes.shape({
      className: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

Hero.defaultProps = {
  metadata: {
    className: '',
    agent: {
      name: 'Kathryn Murphy',
      description: 'Top-agent in New York, NY',
      avatarImageSource:
        'https://d33wubrfki0l68.cloudfront.net/6b6e3a58cac3873d3243fa65ebd4d8b3f944b240/0167b/static/agent-kathy-d9c2f8c478509743f5785733be7ccc5d.png',
    },
    backgroundImage: {
      url: '',
      alt: 'background',
    },
    textContainer: {
      className: '',
      title: 'Title',
      subtitle: 'Subtitle',
      text: 'Text',
    },
  },
  siteType: PropTypes.string,
  title: PropTypes.string,
  openModalHandler: PropTypes.func,
  submitContactForm: PropTypes.func,
};

export default memo(Hero);
