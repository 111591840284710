export const menuItems = [
  {
    id: 1,
    htmlId: 'menu-home',
    displayName: 'Home', // TODO: I18n this
    url: '/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: false,
  },
  {
    id: 2,
    htmlId: 'menu-meet',
    displayName: 'Meet the team', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 3,
    htmlId: 'menu-listings',
    displayName: 'Listings', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 301,
        htmlId: 'menu-listings-active',
        displayName: 'Active listings', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 302,
        htmlId: 'menu-listings-new',
        displayName: 'New listings', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 303,
        htmlId: 'menu-listings-weekend',
        displayName: 'Open this weekend', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 304,
        htmlId: 'menu-listings-sold',
        displayName: 'Sold listings', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 305,
        htmlId: 'menu-listings-soon',
        displayName: 'Coming soon', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 4,
    htmlId: 'menu-buyers',
    displayName: 'Buyers', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 5,
    htmlId: 'menu-sellers',
    displayName: 'Sellers', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 6,
    htmlId: 'menu-for-lease',
    displayName: 'For lease', // TODO: I18n this
    url: 'http://www.domain.com/link',
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 7,
    htmlId: 'menu-communities',
    displayName: 'Communities', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 701,
        htmlId: 'menu-community-lafayette',
        displayName: 'Lafayette', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 702,
        htmlId: 'menu-community-moraga',
        displayName: 'Moraga', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 703,
        htmlId: 'menu-community-orinda',
        displayName: 'Orinda', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 704,
        htmlId: 'menu-community-walnut-creek',
        displayName: 'Walnut Creek', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 705,
        htmlId: 'menu-community-rossmoor',
        displayName: 'Rossmoor', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 706,
        htmlId: 'menu-community-pleasanton',
        displayName: 'Pleasanton', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 707,
        htmlId: 'menu-community-danville',
        displayName: 'Danville', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 708,
        htmlId: 'menu-community-concord',
        displayName: 'Concord', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item communities-menu',
        mobileClassName: 'mobile-nav-submenu-item communities-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 8,
    htmlId: 'menu-concierge',
    displayName: 'Concierge', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 801,
        htmlId: 'menu-utility',
        displayName: 'Utility providers', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item concierge-menu',
        mobileClassName: 'mobile-nav-submenu-item concierge-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 802,
        htmlId: 'menu-city',
        displayName: 'City services', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item concierge-menu',
        mobileClassName: 'mobile-nav-submenu-item concierge-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 803,
        htmlId: 'menu-school',
        displayName: 'School information', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item concierge-menu',
        mobileClassName: 'mobile-nav-submenu-item concierge-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 804,
        htmlId: 'menu-tips',
        displayName: 'Helpful tips', // TODO: I18n this
        url: 'http://www.domain.com/link',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item concierge-menu',
        mobileClassName: 'mobile-nav-submenu-item concierge-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 9,
    htmlId: 'menu-contact',
    displayName: 'Contact', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
];
