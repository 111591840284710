export const HOMELIGHT_URL = {
  HOMELIGHT: 'https://www.homelight.com/',
  TRADE_IN: 'https://www.homelight.com/trade-in',
  TERMS_AND_CONDITIONS: 'https://www.homelight.com/terms',
  TRADE_IN_QUIZ: 'https://www.homelight.com/cash-close/trade-in-quiz#/qaas=0/',
  HOME_LOANS: 'https://www.homelight.com/home-loans',
  CASH_OFFER_QUIZ: 'https://www.homelight.com/cash-close/cash-offer-quiz#/qaas=0/',
  TESTIMONIALS: 'https://www.homelight.com/testimonials',
  BLOG_ARTICLE_1: 'https://www.homelight.com/blog/buyer-trade-in-antwerp-lane-house/',
  BLOG_ARTICLE_2: 'https://www.homelight.com/blog/trade-in-levante-street-house/',
  BLOG_ARTICLE_3: 'https://www.homelight.com/blog/trade-in-cross-country-house/',
  BLOG_ARTICLE_4: 'https://www.homelight.com/blog/trade-in-sitio-granado-house/',
};
