import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import './video-player.scss';

const defaultSettings = {
  width: '100%',
  height: '100%',
  light: false,
  controls: true,
};

const VideoPlayer = (props) => {
  const { settings, url } = props;
  return (
    <div className="on-video-player">
      <div className="video">
        <div className="placeholder-video frame">
          <ReactPlayer
            url={url}
            width={settings.width}
            height={settings.height}
            light={settings.light}
            controls={settings.controls}
          />
        </div>
      </div>
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string,
  settings: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    light: PropTypes.bool,
    controls: PropTypes.bool,
  }),
};

VideoPlayer.defaultProps = { settings: defaultSettings, url: '' };
export default memo(VideoPlayer);
