import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
// eslint-disable-next-line import/no-cycle
import Menu from './menu';

import './menu-item.scss';

const MenuItem = (props) => {
  const { item, isActive, itemClass, isMobile, onItemClick } = props;

  return (
    <li
      className={cs(
        itemClass,
        isMobile ? item.mobileClassName : item.className,
        isActive ? 'active' : '',
        item.children?.length > 0 ? 'has-submenu' : '',
        'align-center',
      )}
      onClick={(e) => {
        e.stopPropagation(); // prevent event bubbling which would close the menu
        onItemClick(item.id);
      }}>
      <a href={isMobile && item.children?.length > 0 ? '#' : item.url}>
        {item.displayName}
        {(!isMobile || (isMobile && isActive && item.children?.length > 0)) && (
          <ul className={isMobile ? 'mobile-nav-submenu' : 'nav-submenu'}>
            {/* keep recursion? */}
            <Menu items={item.children} isMobile={isMobile} />
          </ul>
        )}
      </a>
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object,
  itemClass: PropTypes.string,
  isActive: PropTypes.bool,
  isMobile: PropTypes.bool,
  onItemClick: PropTypes.func,
};

MenuItem.defaultProps = {
  item: {},
  itemClass: '',
  isActive: false,
  isMobile: false,
  onItemClick: () => {},
};

export default memo(MenuItem);
