import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebook,
  faFacebookSquare,
  faInstagramSquare,
  faTwitter,
  faYoutube,
  faLinkedin,
  faInstagram,
  faPinterest,
  faYelp,
  faGooglePlus,
  faGooglePlusSquare,
  faGooglePlusG,
  faFacebookF,
  faLinkedinIn,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';
import {
  fas,
  faBars,
  faRss,
  faMapMarkedAlt,
  faPhoneAlt,
  faPhone,
  faEnvelope,
  faAddressCard,
  faClock,
  faMapMarkerAlt,
  faLink,
  faHome,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import TradeInLayout from './trade-in-layout/trade-in-layout';
import CashOfferLayout from './cash-offer-layout/cash-offer-layout';
import { SITE_TYPE, getSiteType } from '../utils/site-type-validator';

library.add(
  fab,
  fas,
  faGooglePlus,
  faGooglePlusSquare,
  faGooglePlusG,
  faPinterest,
  faBars,
  faFacebook,
  faFacebookSquare,
  faFacebookF,
  faInstagramSquare,
  faTwitter,
  faYoutube,
  faYelp,
  faLinkedin,
  faInstagram,
  faRss,
  faMapMarkedAlt,
  faPhone,
  faPhoneAlt,
  faEnvelope,
  faLinkedinIn,
  faAddressCard,
  faClock,
  faMapMarkerAlt,
  faLink,
  faPinterestP,
  faHome,
  faUser,
);

const Layout = (props) => {
  const { children, pageContext, location } = props;
  const type = getSiteType(location.pathname);

  if (type === SITE_TYPE.CASH_OFFER) {
    return <CashOfferLayout {...props}>{children}</CashOfferLayout>;
  }

  return <TradeInLayout {...props}>{children}</TradeInLayout>;
};

Layout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

Layout.defaultProps = { children: null, pageContext: {}, location: {} };

export default Layout;
