import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

export default class HeaderItems {
  constructor(isDesktop, desktop, mobile, expandFolder, customComponents) {
    this.isDesktop = isDesktop;
    this.desktop = desktop;
    this.mobile = mobile;
    this.expandFolder = expandFolder;
    this.customComponents = customComponents;
  }

  image(id, className, image, url) {
    const img = <img id={id} key={id} src={image.src} alt={image.alt} className={className} />;

    if (url) return this.wrapWithAnchor(img, { id, className, url });

    return img;
  }

  icon(id, className, iconSet, url, content, depth) {
    const iconComp = (
      <div
        key={id}
        className={cs('on-header-icon', {
          [className]: `${className}-container`,
          [this.desktop.itemClassName]: this.isDesktop && !depth,
          [this.desktop.menuItemClassName]: this.isDesktop && depth === 1,
          [this.desktop.subMenuItemClassName]: this.isDesktop && depth === 2,
          [this.mobile.itemClassName]: !this.isDesktop && !depth,
          [this.mobile.menuItemClassName]: !this.isDesktop && depth === 1,
          [this.mobile.subMenuItemClassName]: !this.isDesktop && depth === 2,
        })}
      >
        <FontAwesomeIcon icon={[iconSet, className]} className={className} />
        {content && <p>{content}</p>}
      </div>
    );

    if (url) return this.wrapWithAnchor(iconComp, { id, className: `${className}-anchor`, url });

    return iconComp;
  }

  logo(logo, show) {
    if (!show) return null;

    return (
      <a
        className={cs('on-header-logo-container', {
          [this.desktop.logo.containerClassName]: this.isDesktop,
          [this.mobile.logo.containerClassName]: !this.isDesktop,
        })}
        href={logo.url}
      >
        <img
          src={logo.src}
          alt={logo.alt || 'logo'}
          className={cs('on-header-logo', {
            [this.desktop.logo.imageClassName]: this.isDesktop,
            [this.mobile.logo.imageClassName]: !this.isDesktop,
          })}
        />
      </a>
    );
  }

  url(id, url, className, content, depth) {
    return (
      <a
        id={id}
        key={id}
        href={url}
        className={cs({
          [className]: className,
          [this.desktop.itemClassName]: this.isDesktop && !className && !depth,
          [this.desktop.menuItemClassName]: this.isDesktop && !className && depth === 1,
          [this.desktop.subMenuItemClassName]: this.isDesktop && !className && depth === 2,
          [this.mobile.itemClassName]: !this.isDesktop && !className && !depth,
          [this.mobile.menuItemClassName]: !this.isDesktop && !className && depth === 1,
          [this.mobile.subMenuItemClassName]: !this.isDesktop && !className && depth === 2,
        })}
      >
        {content}
      </a>
    );
  }

  folder({ id, className, content, url, menuItems }, { expandedFolder, expandedSubFolder }, depth) {
    const label = (
      <label
        id={id}
        className={cs({
          'on-header-folder-label': this.isDesktop,
          'on-header-mobile-folder-label': !this.isDesktop,
          [className]: className,
          [this.desktop.itemClassName]: this.isDesktop && !className && !depth,
          [this.mobile.itemClassName]: !this.isDesktop && !className && !depth,
          [this.desktop.menuItemClassName]: this.isDesktop && !className && depth,
          [this.mobile.menuItemClassName]: !this.isDesktop && !className && depth,
          [this.desktop.menuLabelActiveClassName]: this.isDesktop && !className && !depth && id === expandedFolder,
          [this.mobile.menuLabelActiveClassName]:
            !this.isDesktop && !className && (id === expandedFolder || id === expandedSubFolder),
          [this.desktop.subMenuLabelActiveClassName]: this.isDesktop && !className && depth && id === expandedSubFolder,
        })}
        onClick={(e) => this.expandFolder(e, depth === 2)}
      >
        {content}
      </label>
    );

    return (
      <div
        id={`container-${id}`}
        key={id}
        className={cs({
          'on-header-folder': this.isDesktop,
          'on-header-mobile-folder': !this.isDesktop,
          'on-hover-expand-menu': this.isDesktop && !this.desktop.disableOnHoverMenuExpand && !depth,
          'on-hover-expand-sub-menu': this.isDesktop && !this.desktop.disableOnHoverMenuExpand && depth,
        })}
      >
        {url ? this.wrapWithAnchor(label, { url }) : label}
        <div
          className={cs({
            'on-header-menu': this.isDesktop && !depth,
            'on-header-mobile-item-menu': !this.isDesktop && !depth,
            'on-header-sub-menu': this.isDesktop && depth,
            'on-header-mobile-item-sub-menu': !this.isDesktop && depth,
            'on-header-folder-expanded': this.isDesktop && !depth && id === expandedFolder,
            'on-header-mobile-folder-expanded': !this.isDesktop && (id === expandedFolder || id === expandedSubFolder),
            'on-header-sub-folder-expanded': this.isDesktop && depth && id === expandedSubFolder,
            [this.desktop.menuClassName]: this.isDesktop && !depth,
            [this.mobile.menuClassName]: !this.isDesktop && !depth,
            [this.desktop.subMenuClassName]: this.isDesktop && depth,
            [this.mobile.subMenuClassName]: !this.isDesktop && depth,
          })}
        >
          {menuItems.map((item) => this.item(item, { expandedFolder, expandedSubFolder }, depth ? 2 : 1))}
        </div>
      </div>
    );
  }

  item(
    { id, url, image, iconSet, content, className, menuItems, customComponent },
    { expandedFolder, expandedSubFolder },
    depth = 0,
  ) {
    if (customComponent) return this.customComponents[customComponent];

    if (image) return this.image(id, className, image, url);

    if (iconSet) return this.icon(id, className, iconSet, url, content, depth);

    if (menuItems) {
      if (!content)
        return this.div(
          menuItems.map((i) => this.item(i, { expandedFolder, expandedSubFolder })),
          { id, className },
        );

      return this.folder(
        {
          id,
          className,
          content,
          url,
          menuItems,
        },
        { expandedFolder, expandedSubFolder },
        depth,
      );
    }

    return this.url(id, url, className, content, depth);
  }

  div(children, { id, className }) {
    return (
      <div id={id} className={className}>
        {children}
      </div>
    );
  }

  headerSection(children) {
    if (Array.isArray(children)) children = children.filter((child) => child);

    const hasOverride = this.isDesktop
      ? this.desktop.headerSectionsClassNameOverride
      : this.mobile.headerSectionsClassNameOverride;

    return (
      <div
        className={cs({
          'on-header-section': !hasOverride && this.isDesktop,
          'on-header-mobile-section': !hasOverride && !this.isDesktop,
          [hasOverride]: hasOverride,
          [this.desktop.headerSectionsClassName]:
            !hasOverride && this.isDesktop && this.desktop.headerSectionsClassName,
          [this.mobile.headerSectionsClassName]: !hasOverride && !this.isDesktop && this.mobile.headerSectionsClassName,
        })}
      >
        {children}
      </div>
    );
  }

  wrapWithAnchor(children, { id, className, url }) {
    if (!children) return null;

    if (Array.isArray(children)) {
      children = children.filter((child) => child);
      if (children.length === 0) return null;
    }

    return (
      <a id={id} key={id} href={url} className={className}>
        {children}
      </a>
    );
  }

  wrapWithHeader(children, id) {
    if (!children) return null;

    if (Array.isArray(children)) {
      children = children.filter((child) => child);
      if (children.length === 0) return null;
    }

    const isOverride = this.isDesktop ? this.desktop.headerClassNameOverride : this.mobile.headerClassNameOverride;

    return (
      <header
        id={id}
        key={id}
        className={cs({
          [isOverride]: isOverride,
          'on-header': !isOverride,
          [`${this.isDesktop ? this.desktop.type : this.mobile.type}-type`]: !isOverride,
          [this.desktop.headerClassName]: !isOverride && this.isDesktop,
          [this.mobile.headerClassName]: !isOverride && !this.isDesktop,
        })}
      >
        {children}
      </header>
    );
  }
}
