import { memo } from 'react';
import PropTypes from 'prop-types';

import './how-it-works-container.scss';
import { HowItWorks } from '../../../components';

const HowItWorksContainer = ({ metadata }) => <HowItWorks metadata={metadata} />;

HowItWorksContainer.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string,
    itemClassName: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        iconClassName: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
  }),
};

export default memo(HowItWorksContainer);
