import React, { memo } from 'react';
import './bulletpoints.scss';

const Bulletpoints = (props) => {
  const { bulletpoints } = props;
  return (
    <div className="on-bulletpoints">
      <ul>
        {bulletpoints?.map((bulletpoint) => (
          <li>{bulletpoint.text}</li>
        ))}
      </ul>
    </div>
  );
};

export default memo(Bulletpoints);
