import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  HeroContainer,
  HowItWorksContainer,
  PricingTableContainer,
  CallToActionContainer,
  TestimonialsContainer,
  WhyHomelightContainer,
  ClientFaqContainer,
  ContactModalContainer,
} from '../../containers/trade-in';

import { submitContactForm } from '../../api/contact-form/contact-api';
import { SITE_TYPE } from '../../utils/site-type-validator';

import '../common.scss';
import './trade-in-layout.scss';

const Layout = (props) => {
  const {
    location,
    title,
    header,
    footer,
    contactModelContainer,
    heroContainer,
    howItWorksContainer,
    callToActionContainer,
    pricingTableContainer,
    testimonialsContainer,
    whyHomelightContainer,
    clientFaqContainer,
    metadata,
  } = props;
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [pricingTableVisible] = useState(false);
  useEffect(() => {
    if (contactModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [contactModalOpen]);

  return (
    <div className="layout-container">
      {header}
      <div className="on-main-container">
        {contactModelContainer || (
          <ContactModalContainer
            isOpen={contactModalOpen}
            openModalHandler={setContactModalOpen}
            submitContactForm={submitContactForm}
            location={location}
            siteType={SITE_TYPE.TRADE_IN}
            title={title}
          />
        )}
        {heroContainer || (
          <HeroContainer
            metadata={metadata.tradeIn.hero}
            siteType={SITE_TYPE.TRADE_IN}
            title={title}
            openModalHandler={setContactModalOpen}
            submitContactForm={submitContactForm}
          />
        )}
        {howItWorksContainer || <HowItWorksContainer metadata={metadata.tradeIn.howItWorks} title={title} />}
        {pricingTableVisible && (pricingTableContainer || <PricingTableContainer />)}
        {callToActionContainer || (
          <CallToActionContainer metadata={metadata.tradeIn.callToAction} openModalHandler={setContactModalOpen} />
        )}
        {/* {testimonialsContainer || <TestimonialsContainer title={title} metadata={metadata.tradeIn.testimonials} />} */}
        {whyHomelightContainer || <WhyHomelightContainer title={title} metadata={metadata.tradeIn.whyHomelight} />}
        {clientFaqContainer || (
          <ClientFaqContainer
            title={title}
            metadata={metadata.tradeIn.clientFAQ}
            openModalHandler={setContactModalOpen}
          />
        )}
      </div>
      {footer}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Layout.defaultProps = { children: null, location: null };

export default memo(Layout);
