import cs from 'classnames';

export const hamburgerOpener = ({ isReverse, className, isMobileMenuOpen, toggleMobileMenu }) => {
  return (
    <div
      className={cs('on-header-mobile-menu-hamburger', className, {
        'hamburger-move-right': isMobileMenuOpen && isReverse,
        'hamburger-move-left': isMobileMenuOpen && !isReverse,
      })}
      onClick={toggleMobileMenu}
    >
      <div className="hamburger-top-bar" />
      <div className="hamburger-mid-bar" />
      <div className="hamburger-bot-bar" />
    </div>
  );
};

export const sideMenu = (children, { isReverse, className, isMobileMenuOpen }) => {
  return (
    <div
      className={cs(className, {
        'on-header-mobile-menu': !isReverse,
        'on-header-mobile-menu-reversed': isReverse,
        'on-header-mobile-menu-open': isMobileMenuOpen,
      })}
    >
      {children}
    </div>
  );
};
