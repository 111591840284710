import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MoneyHouseIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 97.88 103.49">
      <g id="Layer_2" data-name="Layer 2">
        <g id="strong-offer">
          <circle fill="#e8eef9" cx="48" cy="55.49" r="48" />
          <rect
            fill="#46b6ff"
            x="14.87"
            y="5.01"
            width="32"
            height="15.2"
            transform="translate(6.18 -9.8) rotate(20)"
          />
          <path fill="#008ce7" d="M42.41,23.59,15,13.61l4.36-12,27.43,10ZM16.26,13l25.55,9.3,3.68-10.1L19.94,2.91Z" />
          <circle fill="#008ce7" cx="30.87" cy="12.61" r="3.98" />
          <path fill="#008ce7" d="M19.64,2.27l3,1.1a3.19,3.19,0,0,1-4.09,1.91Z" />
          <path fill="#008ce7" d="M15.62,13.31l1.1-3a3.21,3.21,0,0,1,1.91,4.1Z" />
          <path fill="#008ce7" d="M46.13,11.91l-1.1,3a3.21,3.21,0,0,1-1.91-4.1Z" />
          <path fill="#008ce7" d="M42.11,23l-3-1.09A3.19,3.19,0,0,1,43.2,20Z" />
          <rect
            fill="#46b6ff"
            x="27.2"
            y="28.08"
            width="32"
            height="15.2"
            transform="translate(-9.6 16.93) rotate(-20)"
          />
          <path fill="#008ce7" d="M31.67,46.66l-4.36-12,27.43-10,4.36,12ZM28.59,35.28l3.68,10.1,25.55-9.3L54.14,26Z" />
          <circle fill="#008ce7" cx="43.2" cy="35.68" r="3.98" />
          <path fill="#008ce7" d="M28,35l3-1.09A3.2,3.2,0,0,1,29,38Z" />
          <path fill="#008ce7" d="M32,46l-1.1-3A3.19,3.19,0,0,1,35,44.93Z" />
          <path fill="#008ce7" d="M54.44,25.34l1.09,3a3.21,3.21,0,0,1-4.1-1.91Z" />
          <path fill="#008ce7" d="M58.46,36.38l-3,1.09a3.19,3.19,0,0,1,1.91-4.09Z" />
          <polygon fill="#fff" points="68.54 83.04 31.4 83.04 31.4 57.1 49.97 38.31 68.54 57.1 68.54 83.04" />
          <polygon fill="#bbd3ea" points="97.88 57.1 68.54 57.1 49.97 38.31 77.88 38.31 97.88 57.1" />
          <rect fill="#e8eef9" x="45.75" y="66.38" width="8.44" height="16.65" />
          <rect fill="#e8eef9" x="68.54" y="57.15" width="29.34" height="25.85" />
          <rect fill="#bbd3ea" x="79.77" y="66.38" width="8.44" height="8.44" />
          <circle fill="#bbd3ea" cx="20.3" cy="74.18" r="3.58" />
          <polygon fill="#fff" points="23.07 83.04 17.54 83.04 16.72 77.13 23.88 77.13 23.07 83.04" />
          <path fill="#bbd3ea" d="M73.92,79.56A5.38,5.38,0,1,0,64.43,83h8.23A5.36,5.36,0,0,0,73.92,79.56Z" />
          <path fill="#bbd3ea" d="M36.78,79.56A5.39,5.39,0,1,0,27.28,83h8.23A5.37,5.37,0,0,0,36.78,79.56Z" />
          <rect
            fill="#46b6ff"
            x="14.87"
            y="51.13"
            width="32"
            height="15.2"
            transform="translate(21.95 -7.02) rotate(20)"
          />
          <path fill="#008ce7" d="M42.41,69.71,15,59.73l4.36-12,27.43,10ZM16.26,59.13l25.55,9.3,3.68-10.1L19.94,49Z" />
          <circle fill="#008ce7" cx="30.87" cy="58.73" r="3.98" />
          <path fill="#008ce7" d="M19.64,48.39l3,1.1a3.2,3.2,0,0,1-4.09,1.91Z" />
          <path fill="#008ce7" d="M15.62,59.43l1.1-3a3.2,3.2,0,0,1,1.91,4.09Z" />
          <path fill="#008ce7" d="M46.13,58,45,61a3.21,3.21,0,0,1-1.91-4.1Z" />
          <path fill="#008ce7" d="M42.11,69.07l-3-1.09a3.18,3.18,0,0,1,4.09-1.91Z" />
        </g>
      </g>
    </svg>
  );
};

MoneyHouseIcon.propTypes = {
  className: PropTypes.string,
};

MoneyHouseIcon.defaultProps = { className: '' };

export default memo(MoneyHouseIcon);
