const PATH_REGEX = /([^[.\]])+/g;

const setValueToMetadata = (metadata, rawKey, value) => {
  const keys = rawKey.match(PATH_REGEX);
  keys.reduce((currentLayer, currentKey, index) => {
    if (currentLayer[currentKey] === undefined) currentLayer[currentKey] = {};
    if (index === keys.length - 1) currentLayer[currentKey] = value;
    return currentLayer[currentKey];
  }, metadata);
};

const getValueFromMetadata = (metadata, rawKey) => {
  const keys = rawKey.match(PATH_REGEX);
  const value = keys.reduce((currentLayer, currentKey) => currentLayer && currentLayer[currentKey], metadata);
  return value === undefined ? null : value;
};

const replacementReplacements = (metadata, siteData, replacements) => {
  Object.entries(replacements).forEach(([keyPath, valueKey]) => {
    let value = metadata.defaultData[valueKey];
    if (siteData[valueKey] !== undefined) value = siteData[valueKey];
    setValueToMetadata(metadata, keyPath, value);
  });
};

const substituteReplacements = (metadata, siteData, substitutions) => {
  Object.entries(substitutions).forEach(([keyPath, valueKey]) => {
    let substituteValue = metadata.defaultData[valueKey];
    if (siteData[valueKey] !== undefined) substituteValue = siteData[valueKey];

    const currentValue = getValueFromMetadata(metadata, keyPath).replaceAll(`{{${valueKey}}}`, substituteValue);
    setValueToMetadata(metadata, keyPath, currentValue);
  });
};

const populateMetadata = (baseMetadata, siteMetadata) => {
  substituteReplacements(baseMetadata, siteMetadata.siteData, baseMetadata.substitute);
  substituteReplacements(baseMetadata, siteMetadata.siteData, siteMetadata.substitute);
  replacementReplacements(baseMetadata, siteMetadata.siteData, baseMetadata.replace);
  replacementReplacements(baseMetadata, siteMetadata.siteData, siteMetadata.replace);
};

export default populateMetadata;
