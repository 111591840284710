import { memo } from 'react';
import PropTypes from 'prop-types';

import './why-homelight-container.scss';
import { WhyHomelight } from '../../../components';

const WhyHomelightContainer = ({ metadata }) => (
  <div className="on-why-homelight">
    <WhyHomelight metadata={metadata} />
  </div>
);

WhyHomelightContainer.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    heading: PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
    }),
    textContainer: PropTypes.shape({
      className: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  }),
};

export default memo(WhyHomelightContainer);
