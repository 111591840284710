import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialLink = (props) => {
  const { item, linkClassName, iconTypeClassName, iconClassName, textClassName, showText, target } = props;

  return (
    <a target={target} key={item.id} className={cs(linkClassName)} href={item.url}>
      <FontAwesomeIcon icon={[iconTypeClassName, item.iconClassName]} className={iconClassName} />
      {showText && <span className={textClassName}>{item.text}</span>}
    </a>
  );
};

SocialLink.propTypes = {
  item: PropTypes.object,
  linkClassName: PropTypes.string,
  iconTypeClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
  showText: PropTypes.bool,
  target: PropTypes.string,
};

SocialLink.defaultProps = {
  item: {},
  linkClassName: '',
  iconTypeClassName: '',
  iconClassName: 'btn',
  textClassName: '',
  showText: false,
  target: '_blank',
};

export default memo(SocialLink);
