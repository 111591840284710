import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '../menu';
import './mobile-nav.scss';

const MobileNav = (props) => {
  const { items, isOpen, toggleNav } = props;
  return (
    <div className={isOpen ? 'mobile-nav-overlay mobile-nav-open' : 'mobile-nav-overlay'} onClick={toggleNav}>
      <div className={isOpen ? 'mobile-nav mobile-nav-open' : 'mobile-nav'}>
        <ul className="mobile-nav-menu d-block d-md-none align-center">
          <Menu items={items} isMobile />
        </ul>
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  items: PropTypes.array,
  toggleNav: PropTypes.func,
  isOpen: PropTypes.bool,
};

MobileNav.defaultProps = {
  items: [],
  toggleNav: () => {},
  isOpen: false,
};

export default memo(MobileNav);
