import { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import './why-homelight.scss';

const WhyHomelight = ({ metadata }) => (
  <div
    className={cs('on-why-homelight-container', {
      [metadata.className]: metadata.className,
    })}
  >
    <div className="on-why-homelight-content">
      <img alt={metadata.image.alt} src={metadata.image.src} style={metadata.image.style} />
      <div className="on-why-homelight-text-wrapper">
        <div className="on-why-homelight-text">
          <div className="heading">
            <h2>{metadata.heading.title}</h2>
            <em>{metadata.heading.subTitle}</em>
          </div>
          <div className={`text-wrap ${metadata.textContainer.className}`}>
            {metadata?.textContainer?.content?.map((text, index) => (
              <p key={`${text[0]}${index}`}>{text}</p>
            ))}
          </div>
          {metadata.textContainer.link.show && (
            <a className="link" href={metadata.textContainer.link.url} rel="noreferrer noopener" target="_blank">
              {metadata.textContainer.link.text}
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
);

WhyHomelight.propTypes = {
  metadata: PropTypes.shape({
    className: PropTypes.string,
    heading: PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
    }),
    textContainer: PropTypes.shape({
      className: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  }),
};

WhyHomelight.defaultProps = {
  metadata: {
    className: '',
    heading: {
      title: 'Title',
      subTitle: 'Sub Title',
    },
    textContainer: {
      className: '',
      content: ['Content'],
      link: {
        url: '/',
        text: 'link',
      },
    },
  },
};

export default memo(WhyHomelight);
